import { Text, TouchableOpacity, View } from "react-native";
import { graphql } from "../gql";
import type { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import TextInput from "../components/text-input";
import { useState } from "react";
import { useAuth } from "../hooks/auth";
import { setFullNameDocument } from "../queries/profile";

const SetFullNameScreen: React.FC<
  BottomTabScreenProps<RootStackParamList, "SetFullName">
> = ({ navigation, route }) => {
  const { graphqlRequest } = useAuth();
  const queryClient = useQueryClient();
  const [fullName, setFullName] = useState(route.params.fullName);
  const setFullNameMutation = useMutation(
    async (fullName: string | null) =>
      graphqlRequest(setFullNameDocument, { fullName }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        navigation.goBack();
      },
    }
  );
  return (
    <View className="w-full flex-1 p-8">
      <View className="w-full flex-row">
        <TextInput
          className="w-[80%] rounded-l bg-zinc-700 p-4 text-zinc-200"
          value={fullName ?? undefined}
          onChangeText={setFullName}
        />
        <TouchableOpacity
          className="w-[20%] items-center justify-center rounded-r bg-zinc-400 p-4"
          onPress={() => setFullNameMutation.mutate(fullName)}
        >
          <Text className="text-zinc-900">Done</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default SetFullNameScreen;
