import colors from "tailwindcss/colors";
import React, { useState } from "react";
import CountryPicker, { CountryCode } from "react-native-country-picker-modal";
import {
  View,
  TouchableOpacity,
  TextInput,
  Text,
  ImageStyle,
} from "react-native";
import { PhoneNumberUtil } from "google-libphonenumber";
import Ionicons from "@expo/vector-icons/Ionicons";
import { isNumeric } from "../util";

const phoneUtil = PhoneNumberUtil.getInstance();

type Props = {
  onPhoneNumberChange: (phoneNumber: string, isValid: boolean) => void;
};
const closeButtonImageStyle: ImageStyle = {
  backgroundColor: colors.zinc[200],
};
const isValidNumber = (number: string, countryCode: CountryCode) => {
  try {
    const parsedNumber = phoneUtil.parse(number, countryCode);
    return phoneUtil.isValidNumber(parsedNumber);
  } catch (err) {
    return false;
  }
};
const PhoneInput: React.FC<Props> = ({ onPhoneNumberChange }) => {
  const [visible, setVisible] = useState(false);
  const [countryCode, setCountry] = useState<CountryCode>("CA");
  const [callingCode, setCallingCode] = useState("+1");
  const [phoneNumber, setPhoneNumber] = useState("");
  const handleChange = (callingCode: string, phoneNumber: string) => {
    const value = callingCode + phoneNumber;
    const isValid = isValidNumber(value, countryCode);
    onPhoneNumberChange(value, isValid);
  };
  return (
    <View className="flex-row items-center gap-4 rounded bg-zinc-700 p-4">
      <TouchableOpacity
        className="flex-row items-center"
        onPress={() => setVisible(true)}
      >
        <CountryPicker
          countryCode={countryCode}
          onSelect={(country) => {
            const callingCode = "+" + country.callingCode[0];
            setCountry(country.cca2);
            setCallingCode(callingCode);
            handleChange(callingCode, phoneNumber);
          }}
          visible={visible}
          onClose={() => setVisible(false)}
          withFlag
          withEmoji
          withCallingCode
          withFilter
          countryCodes={["CA", "US", "NL", "GB", "AU", "NZ", "MX", "IE", "JM"]}
          theme={{
            backgroundColor: colors.zinc[900],
            primaryColor: colors.zinc[200],
            onBackgroundTextColor: colors.zinc[200],
          }}
          //@ts-ignore
          closeButtonImageStyle={closeButtonImageStyle}
        />
        <Ionicons name="chevron-down-outline" />
      </TouchableOpacity>
      <Text className="text-zinc-200">{callingCode}</Text>
      <TextInput
        className="rounded bg-zinc-600 p-2 text-zinc-200"
        placeholder="Phone Number"
        keyboardType="phone-pad"
        autoComplete="tel"
        value={phoneNumber}
        onKeyPress={(e) => {
          if (
            !(isNumeric(e.nativeEvent.key) || e.nativeEvent.key == "Backspace")
          ) {
            e.preventDefault();
          }
        }}
        onChangeText={(phoneNumber) => {
          setPhoneNumber(phoneNumber);
          handleChange(callingCode, phoneNumber);
        }}
      />
    </View>
  );
};

export default PhoneInput;
