import React, { useEffect, useState } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  TextInput,
  ActivityIndicator,
} from "react-native";
import { graphql } from "../gql";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useAuth } from "../hooks/auth";
import { getRecommendationDocument } from "../queries/recommendation";

const editRecommendationDocument = graphql(`
  mutation editRecommendation(
    $recommendationId: String!
    $description: String
  ) {
    editRecommendation(
      recommendationId: $recommendationId
      description: $description
    ) {
      id
    }
  }
`);

const EditRecommendationScreen: React.FC<
  NativeStackScreenProps<RootStackParamList, "EditRecommendation">
> = ({ navigation, route }) => {
  const recommendationId = route.params.recommendationId;
  const queryClient = useQueryClient();
  const { graphqlRequest } = useAuth();
  const [description, setDescription] = useState("");
  const recommendationQuery = useQuery({
    queryKey: ["recommendation", recommendationId],
    queryFn: () =>
      graphqlRequest(getRecommendationDocument, { recommendationId }),
  });
  const editRecommendation = useMutation(
    async ({ description }: { description?: string }) =>
      graphqlRequest(editRecommendationDocument, {
        recommendationId,
        description,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        navigation.goBack();
      },
    }
  );
  useEffect(() => {
    setDescription(
      recommendationQuery.data?.getRecommendation.description || ""
    );
  }, [recommendationQuery.data?.getRecommendation.description]);
  if (!recommendationQuery.data) {
    return <ActivityIndicator />;
  }
  const { location, description: savedDescription } =
    recommendationQuery.data.getRecommendation;
  const saveDisabled = description == savedDescription;
  return (
    <View className="flex-1 items-center justify-start space-y-2 p-4">
      <View className="relative z-10 w-full flex-initial">
        <Text className="text-zinc-200">Place</Text>
        <View>
          <Text className="text-zinc-200">{location.name}</Text>
          <Text className="text-zinc-200">{location.formattedAddress}</Text>
        </View>
      </View>
      <View className="relative w-full flex-initial">
        <Text className="text-zinc-200">Description</Text>
        <TextInput
          className="w-full rounded bg-zinc-700 p-2 text-zinc-200"
          multiline
          numberOfLines={10}
          value={description}
          onChangeText={setDescription}
        />
      </View>
      <TouchableOpacity
        className={`w-full flex-initial items-center rounded ${
          saveDisabled ? "bg-zinc-500" : "bg-zinc-200"
        } p-4`}
        disabled={saveDisabled}
        onPress={() => editRecommendation.mutate({ description })}
      >
        <Text className=" text-zinc-800">Save</Text>
      </TouchableOpacity>
      <TouchableOpacity
        className="w-full flex-initial items-center rounded bg-zinc-700 p-4"
        onPress={() => navigation.goBack()}
      >
        <Text className=" text-zinc-200">Cancel</Text>
      </TouchableOpacity>
    </View>
  );
};

export default EditRecommendationScreen;
