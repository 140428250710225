import {
  ActivityIndicator,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { graphql } from "../gql";
import type { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { useAuth } from "../hooks/auth";

const searchDocument = graphql(`
  query search($query: String!) {
    search(query: $query) {
      __typename
      ... on User {
        id
        username
        fullName
      }
      ... on Recommendation {
        id
        location {
          name
        }
        createdByUser {
          username
          fullName
        }
        description
      }
      ... on Location {
        id
        name
        formattedAddress
      }
    }
  }
`);

const SearchScreen: React.FC<
  BottomTabScreenProps<RootStackParamList, "Search">
> = ({ navigation }) => {
  const { graphqlRequest } = useAuth();
  const [query, setQuery] = useState("");
  const searchMutation = useMutation(async (query: string) =>
    graphqlRequest(searchDocument, { query })
  );

  return (
    <SafeAreaView className="flex-1">
      <View className="flex-1 items-center justify-start space-y-2 p-4">
        <TextInput
          className="w-full rounded bg-zinc-700 p-4 text-zinc-200"
          onChangeText={setQuery}
          placeholder="search"
          onKeyPress={(e) =>
            e.nativeEvent.key == "Enter" &&
            query !== "" &&
            searchMutation.mutate(query)
          }
        />
        <ScrollView className="flex w-full shrink grow basis-0">
          <View className="space-y-2">
            {searchMutation.isLoading && <ActivityIndicator />}
            {!searchMutation.isLoading &&
              searchMutation.data &&
              searchMutation.data.search.length == 0 && (
                <Text className="w-full text-center text-xl text-zinc-200">
                  No results found
                </Text>
              )}
            {!searchMutation.isLoading &&
              searchMutation.data &&
              searchMutation.data.search.map((result) => {
                switch (result.__typename) {
                  case "User": {
                    return (
                      <TouchableOpacity
                        className="flex-row space-x-1 rounded bg-sky-800 p-4"
                        onPress={() =>
                          navigation.navigate("UserProfile", {
                            userId: result.id,
                          })
                        }
                      >
                        <Text className="text-zinc-200">{result.username}</Text>
                        <Text className="text-zinc-400">User</Text>
                      </TouchableOpacity>
                    );
                  }
                  case "Location": {
                    return (
                      <TouchableOpacity
                        className="flex-row space-x-1 rounded bg-cyan-900 p-4"
                        onPress={() =>
                          navigation.navigate("Location", {
                            locationId: result.id,
                          })
                        }
                      >
                        <Text className="text-zinc-200">{result.name}</Text>
                        <Text className="text-zinc-400">Location</Text>
                        <Text className="text-zinc-400">
                          {result.formattedAddress}
                        </Text>
                      </TouchableOpacity>
                    );
                  }
                  case "Recommendation": {
                    return (
                      <TouchableOpacity
                        className="flex-row space-x-1 rounded bg-emerald-800 p-4"
                        onPress={() =>
                          navigation.navigate("ViewRecommendation", {
                            recommendationId: result.id,
                          })
                        }
                      >
                        <Text className="text-zinc-200">
                          {result.location.name}
                        </Text>
                        <Text className="text-zinc-400">Recommendation</Text>
                        <Text className="text-zinc-400">{`by ${result.createdByUser.username}`}</Text>
                      </TouchableOpacity>
                    );
                  }
                }
              })}
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

export default SearchScreen;
