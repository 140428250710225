import React, {
  useState,
  useContext,
  createContext,
  PropsWithChildren,
  useEffect,
} from "react";
import { useMutation } from "@tanstack/react-query";
import { useApi, User } from "./api";
import jwtDecode from "jwt-decode";
import { JwtPayload } from "jwt-decode";
import * as SecureStore from "../storage/secure-store";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { ApiClient } from "./api";
import { ActivityIndicator, View } from "react-native";
import { showErrorToast } from "../components/error-toast";

const useProvideAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const api = useApi()!;
  const useLogin = (options?: {
    onSuccess?: () => void;
    onError?: (err: unknown) => void;
  }) => {
    return useMutation(api.login.bind(api), {
      onSuccess: (resp) => {
        SecureStore.setItem("refresh-token", resp.refreshToken);
        setUser({
          type: "authenticated",
          userId: resp.userId,
          accessToken: resp.accessToken,
        });
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (err) => {
        showErrorToast(err.description);
        if (options?.onError) {
          options.onError(err);
        }
      },
    });
  };
  const useSignup = (options?: {
    onSuccess?: () => void;
    onError?: (err: unknown) => void;
  }) => {
    return useMutation(api.signup.bind(api), {
      onSuccess: (resp) => {
        SecureStore.setItem("refresh-token", resp.refreshToken);
        setUser({
          type: "authenticated",
          userId: resp.userId,
          accessToken: resp.accessToken,
        });
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (err) => {
        showErrorToast(err.description);
        if (options?.onError) {
          options.onError(err);
        }
      },
    });
  };
  const logout = () => {
    SecureStore.getItem("refresh-token").then((refreshToken) => {
      if (refreshToken) {
        api.revokeRefreshToken({ refreshToken });
        SecureStore.deleteItem("refresh-token");
      }
    });
    setUser(null);
  };

  const graphqlRequest = async <TResult, TVariables>(
    graphqlDocument: TypedDocumentNode<TResult, TVariables>,
    ...variables: TVariables extends Record<string, never> ? [] : [TVariables]
  ): Promise<TResult> => {
    if (user == null) {
      throw Error("graphql request attempted before auth");
    }
    let decoded = jwtDecode<JwtPayload>(user.accessToken);
    if (decoded.exp && decoded.exp < Math.floor(Number(new Date()) / 1000)) {
      let user = await performTokenRefresh(api);
      setUser(user);
      if (user == null) {
        throw Error("failed to refresh token");
      }
    }
    return api.graphqlRequest(user.accessToken, graphqlDocument, ...variables);
  };

  useEffect(() => {
    const effect = async () => {
      if (user != null) {
        if (user.type == "authenticated") {
          api
            .getMeAuth(user.accessToken)
            .then((data) => setIsAdmin(data.isAdmin));
        }
        return;
      }
      {
        let user = await performTokenRefresh(api);
        if (user != null) {
          setUser(user);
          return;
        }
      }
      let visitorToken = await SecureStore.getItem("visitor-token");
      if (visitorToken == null) {
        visitorToken = (await api.createVisitorAccessToken()).visitorToken;
        await SecureStore.setItem("visitor-token", visitorToken);
      }
      let decoded = jwtDecode<JwtPayload>(visitorToken);
      if (decoded.sub) {
        setUser({
          type: "visitor",
          visitorId: decoded.sub,
          accessToken: visitorToken,
        });
        return;
      }
    };
    effect();
  }, [user]);

  // Return the user object and auth methods
  return {
    user,
    isLoggedIn: user != null && user.type === "authenticated",
    isAdmin,
    useLogin,
    useSignup,
    logout,
    graphqlRequest,
  };
};

const performTokenRefresh = async (api: ApiClient): Promise<User | null> => {
  let refreshToken = await SecureStore.getItem("refresh-token");
  if (refreshToken == null) {
    return null;
  }
  console.log(refreshToken);
  return api
    .loginWithRefreshToken({ refreshToken })
    .then((resp) => {
      SecureStore.setItem("refresh-token", resp.refreshToken);
      return {
        type: "authenticated",
        userId: resp.userId,
        accessToken: resp.accessToken,
      } as const;
    })
    .catch(() => null);
};

type AuthContextValue = ReturnType<typeof useProvideAuth>;
const authContext = createContext<AuthContextValue>({} as AuthContextValue);

export const ProvideAuth: React.FC<PropsWithChildren> = ({ children }) => {
  const auth = useProvideAuth();
  if (auth.user == null) {
    return (
      <View className="flex-1 items-center justify-center">
        <ActivityIndicator size={"large"} />
      </View>
    );
  }
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext)!;
};
