import { Text, TouchableOpacity, View } from "react-native";
import ProfilePhoto from "./profile-photo";
import { timeSince } from "../util";

type Recommendation = {
  id: string;
  location: {
    name: string;
    locality?: string | null | undefined;
  };
  description?: string | null;
  createdByUser: {
    username: string;
    fullName?: string | null | undefined;
    profilePhoto?: {
      url: string;
    } | null;
  };
  createdAt: string;
};
type Props = {
  recommendation: Recommendation;
  onPress: (params: { recommendationId: string }) => void;
};

const RecommendationTile: React.FC<Props> = ({ recommendation, onPress }) => {
  return (
    <TouchableOpacity
      className="w-full flex-col space-y-2 p-4 py-8"
      onPress={() =>
        onPress({
          recommendationId: recommendation.id,
        })
      }
    >
      <View className="flex-row items-center justify-between space-x-8">
        <View className="flex-shrink flex-col items-start space-y-1">
          <View className="flex-row items-end space-x-1">
            {recommendation.createdByUser.profilePhoto?.url && (
              <ProfilePhoto
                imageUri={recommendation.createdByUser.profilePhoto.url}
                size={30}
              />
            )}
            <Text className="text-sm text-zinc-200">{`@${recommendation.createdByUser.username}`}</Text>
            <Text className="text-sm text-zinc-400">recommends</Text>
          </View>
          <Text className="text-base text-zinc-200">{`${recommendation.location.name}`}</Text>
        </View>
        <View className="flex-col items-end">
          <Text className="text-zinc-400">{`${timeSince(
            new Date(recommendation.createdAt + "Z")
          )} ago`}</Text>
          <Text className="text-zinc-200">
            {recommendation.location.locality ?? ""}
          </Text>
        </View>
      </View>
      {recommendation.description ? (
        <View className="flex-row space-x-2">
          <Text
            className="w-60 text-zinc-400"
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {recommendation.description}
          </Text>
          <Text className="text-zinc-300">See more</Text>
        </View>
      ) : (
        <Text className="text-zinc-400">no description</Text>
      )}
    </TouchableOpacity>
  );
};

export default RecommendationTile;
