import { ActivityIndicator, Text, TouchableOpacity, View } from "react-native";
import { graphql } from "../gql";
import type { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../hooks/auth";
import ProfilePhoto from "../components/profile-photo";

const editableProfileFieldsDocument = graphql(`
  query editableProfileFields {
    getMe {
      username
      fullName
      profilePhoto {
        url(width: 120, height: 120)
      }
      bio
    }
  }
`);

const EditProfileScreen: React.FC<
  BottomTabScreenProps<RootStackParamList, "EditProfile">
> = ({ navigation }) => {
  const { graphqlRequest } = useAuth();
  const { data } = useQuery({
    queryKey: ["editableProfileFields"],
    queryFn: async () => graphqlRequest(editableProfileFieldsDocument),
  });
  if (!data) {
    return <ActivityIndicator />;
  }
  return (
    <View className="flex-1 space-y-4 p-8">
      <TouchableOpacity
        onPress={() => navigation.navigate("ChangeProfilePicture")}
      >
        <ProfilePhoto imageUri={data.getMe.profilePhoto?.url} />
      </TouchableOpacity>
      <TouchableOpacity
        className="w-full flex-row space-x-4"
        onPress={() =>
          navigation.navigate("SetFullName", {
            fullName: data.getMe.fullName ?? null,
          })
        }
      >
        <Text className="w-[30%] text-xl text-zinc-400">Name</Text>
        <Text className="w-[70%] border-b-[0.5px] border-zinc-400 px-1 text-2xl text-zinc-200">
          {data.getMe.fullName}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        className="w-full flex-row space-x-4"
        onPress={() =>
          navigation.navigate("ChangeUsername", {
            username: data.getMe.username,
          })
        }
      >
        <Text className="w-[30%] text-xl text-zinc-400">Username</Text>
        <Text className="w-[70%] border-b-[0.5px] border-zinc-400 px-1 text-2xl text-zinc-200">
          {data.getMe.username}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        className="w-full flex-row space-x-4"
        onPress={() =>
          navigation.navigate("SetBio", {
            bio: data.getMe.bio ?? null,
          })
        }
      >
        <Text className="w-[30%] text-xl text-zinc-400">Bio</Text>
        <Text className="w-[70%] border-b-[0.5px] border-zinc-400 px-1 text-2xl text-zinc-200">
          {data.getMe.bio}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default EditProfileScreen;
