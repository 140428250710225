import { useAssets } from "expo-asset";
import { Image, Linking, Text, TouchableOpacity } from "react-native";

const GoogleMapsLink: React.FC<{ googleMapsUrl: string }> = ({
  googleMapsUrl,
}) => {
  const [assets, error] = useAssets(
    require("../../assets/google_maps_icon.png")
  );
  if (error || assets === undefined) {
    return <></>;
  }
  return (
    <TouchableOpacity
      className="flex-row gap-2"
      onPress={() => Linking.openURL(googleMapsUrl)}
    >
      <Text className="text-blue-400">{"Open in google maps"}</Text>
    </TouchableOpacity>
  );
};

export default GoogleMapsLink;
