import { Linking, Text, View } from "react-native";
import FontAwesome from "@expo/vector-icons/FontAwesome5";
import { TouchableOpacity } from "react-native-gesture-handler";


const SupportScreen: React.FC<{}> = () => {
  return (
    <View className="flex-1 items-center justify-center space-y-4 p-4">
      <Text className="text-md text-zinc-200">
        Tastebook is a work in progress, if you have any issues, ideas, or
        general feedback, we'd love to hear from you in our discord
      </Text>
      <TouchableOpacity
        className="rounded bg-zinc-200 p-2"
        onPress={() => Linking.openURL("https://discord.gg/RT2uakEM")}
      >
        <Text className="text-md text-zinc-800">
          {"Join Tastebook User Feedback "}
          <FontAwesome name="discord" size={20} />
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default SupportScreen;
