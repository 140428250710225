import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  StackScreenProps,
  createStackNavigator,
} from "@react-navigation/stack";
import HomeScreen from "./Home";
import LoginScreen from "./Login";
import { useAuth } from "../hooks/auth";
import { Text, TouchableOpacity, View } from "react-native";
import { styled } from "nativewind";
import ProfileScreen from "./MyProfile";
import NewRecommendationScreen from "./NewRecommendation";
import SignupScreen from "./Signup";
import UserProfileScreen from "./UserProfile";
import ViewRecommendationScreen from "./ViewRecommendation";
import Ionicons from "@expo/vector-icons/Ionicons";
import PasswordResetScreen from "./PasswordReset";
import SearchScreen from "./Search";
import LocationScreen from "./Location";
import EditProfileScreen from "./EditProfile";
import SetFullNameScreen from "./SetFullName";
import ChangeUsernameScreen from "./ChangeUsername";
import ChangeProfilePictureScreen from "./ChangeProfilePicture";
import {
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
  DrawerToggleButton,
  createDrawerNavigator,
} from "@react-navigation/drawer";

const Tab = createBottomTabNavigator<RootStackParamList>();
const Stack = createStackNavigator<RootStackParamList>();
const Drawer = createDrawerNavigator();
const LoginLogoutButton = styled(
  TouchableOpacity,
  "bg-emerald-700 p-3 mr-6 rounded"
);

import colors from "tailwindcss/colors";
import { SafeAreaView } from "react-native-safe-area-context";
import SupportScreen from "./SupportScreen";
import FollowersScreen from "./Followers";
import FollowingScreen from "./Following";
import EditRecommendationScreen from "./EditRecommendation";
import SavedRecommendationsScreen from "./SavedRecommendations";
import SetBioScreen from "./SetBio";
import LandingScreen from "./Landing";
import SendUserInviteScreen from "./SendUserInvite";
import OnboardingScreen from "./Onboarding";
import MapScreen from "./Map";

const NoopScreenComponent = () => <></>;

const ProfileDrawer = () => {
  let auth = useAuth();
  return (
    <Drawer.Navigator
      screenOptions={{
        drawerPosition: "right",
        drawerType: "slide",
        headerLeft: () => <></>,
        headerRight: () => <DrawerToggleButton />,
      }}
      drawerContent={(props) => {
        return (
          <DrawerContentScrollView {...props}>
            <DrawerItemList {...props} />
            <DrawerItem label="Logout" onPress={auth.logout} />
          </DrawerContentScrollView>
        );
      }}
    >
      <Drawer.Screen
        name="Profile"
        // @ts-ignore
        component={ProfileScreen}
      />
      <Drawer.Screen
        name="SavedRecommendations"
        // @ts-ignore
        component={SavedRecommendationsScreen}
        options={{
          title: "Saved Recommendations",
        }}
      />
      {auth.isAdmin && (
        <Drawer.Screen
          name="SendUserInvite"
          component={SendUserInviteScreen}
          options={{
            title: "Send Invite",
          }}
        />
      )}
      <Drawer.Screen
        name="Support"
        component={SupportScreen}
        options={{
          title: "Help / Feedback",
        }}
      />
    </Drawer.Navigator>
  );
};

const TabNavigator: React.FC<StackScreenProps<RootStackParamList, "Tabs">> = ({
  navigation,
}) => {
  let auth = useAuth();
  const isLoggedIn = auth.isLoggedIn;
  return (
    <Tab.Navigator>
      <Tab.Screen
        name="Home"
        component={HomeScreen}
        options={() => ({
          header: () => (
            <SafeAreaView
              style={{ height: 1, backgroundColor: colors.zinc[700] }}
            ></SafeAreaView>
          ),
          title: "Home",
          tabBarIcon: ({ size, focused, color }) => (
            <Ionicons
              name={focused ? "home" : "home-outline"}
              size={size}
              color={color}
            />
          ),
        })}
      />
      <Tab.Screen
        name="Map"
        component={MapScreen}
        options={{
          headerShown: false,
          tabBarIcon: ({ size, focused, color }) => (
            <Ionicons
              name={focused ? "map" : "map-outline"}
              size={size}
              color={color}
            />
          ),
        }}
      />
      {isLoggedIn && (
        <Tab.Screen
          // @ts-ignore
          name="NewRecommendationButton"
          component={NoopScreenComponent}
          options={{
            tabBarLabel: "Post",
            tabBarIcon: ({ size, color }) => (
              <Ionicons name="add-circle" color={color} size={size} />
            ),
          }}
          listeners={() => ({
            tabPress: (e) => {
              e.preventDefault();
              navigation.navigate("NewRecommendation");
            },
          })}
        />
      )}
      <Tab.Screen
        name="Search"
        component={SearchScreen}
        options={{
          headerTitle: "",
          tabBarIcon: ({ size, focused, color }) => (
            <Ionicons
              name={focused ? "search-circle" : "search-circle-outline"}
              size={size + 4}
              color={color}
            />
          ),
          headerShown: !isLoggedIn,
        }}
      />
      {isLoggedIn && (
        <Tab.Screen
          name="ProfileDrawer"
          component={ProfileDrawer}
          listeners={({ navigation }) => ({
            tabPress: (event) => {
              event.preventDefault();
              navigation.navigate("ProfileDrawer", { screen: "Profile" });
            },
          })}
          options={{
            title: "Profile",
            tabBarIcon: ({ size, focused, color }) => (
              <Ionicons
                name={focused ? "person-circle" : "person-circle-outline"}
                size={size}
                color={color}
              />
            ),
            headerShown: false,
          }}
        />
      )}
    </Tab.Navigator>
  );
};

const BaseNavigator = () => {
  const auth = useAuth();
  const isLoggedIn = auth.isLoggedIn;
  return (
    <Stack.Navigator
      screenOptions={{
        animationEnabled: true,
      }}
      initialRouteName={isLoggedIn ? "Tabs" : "Landing"}
    >
      {!isLoggedIn ? (
        <>
          <Stack.Screen
            name="Landing"
            options={{ headerShown: false }}
            component={LandingScreen}
          />
          <Stack.Screen
            name="Signup"
            options={{ title: "" }}
            component={SignupScreen}
          />
          <Stack.Screen name="Login" component={LoginScreen} />
        </>
      ) : (
        <>
          <Stack.Screen
            name="Tabs"
            component={TabNavigator}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="UserProfile"
            component={UserProfileScreen}
            options={{ headerTitle: "" }}
          />
          <Stack.Screen name="Followers" component={FollowersScreen} />
          <Stack.Screen name="Following" component={FollowingScreen} />
          <Stack.Screen
            name="NewRecommendation"
            component={NewRecommendationScreen}
            options={{
              headerTitle: "Post Recommendation",
              presentation: "modal",
            }}
          />
          <Stack.Screen
            name="ViewRecommendation"
            component={ViewRecommendationScreen}
            options={{ headerTitle: "Recommendation" }}
          />
          <Stack.Screen
            name="EditRecommendation"
            component={EditRecommendationScreen}
            options={{ headerTitle: "Edit Recommendation" }}
          />
          <Stack.Screen name="Location" component={LocationScreen} />
          <Stack.Screen
            name="SetFullName"
            component={SetFullNameScreen}
            options={{ title: "Full Name" }}
          />
          <Stack.Screen
            name="SetBio"
            component={SetBioScreen}
            options={{ title: "Bio" }}
          />
          <Stack.Screen
            name="ChangeUsername"
            component={ChangeUsernameScreen}
            options={{ title: "Username" }}
          />
          <Stack.Screen
            name="ChangeProfilePicture"
            component={ChangeProfilePictureScreen}
            options={{ title: "Profile Picture" }}
          />
          <Stack.Group screenOptions={{ presentation: "modal" }}>
            <Stack.Screen name="EditProfile" component={EditProfileScreen} />
          </Stack.Group>
        </>
      )}
      <Stack.Screen
        name="Onboarding"
        component={OnboardingScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PasswordReset"
        component={PasswordResetScreen}
        options={{ headerTitle: "Password Reset" }}
      />
    </Stack.Navigator>
  );
};

export default BaseNavigator;
