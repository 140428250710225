import {
  ActivityIndicator,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  Alert,
  Platform,
} from "react-native";
import { graphql } from "../gql";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../hooks/auth";
import ProfilePhoto from "../components/profile-photo";
import Ionicons from "@expo/vector-icons/Ionicons";
import colors from "tailwindcss/colors";
import {
  getRecommendationDocument,
  saveRecommendationDocument,
} from "../queries/recommendation";
import { StackScreenProps } from "@react-navigation/stack";
import { useEffect, useLayoutEffect } from "react";
import { timeSince } from "../util";

const deleteRecommendationDocument = graphql(`
  mutation deleteRecommendation($recommendationId: String!) {
    deleteRecommendation(recommendationId: $recommendationId)
  }
`);

const recordViewedRecommendationDocument = graphql(`
  mutation recordRecommendationViewed($recommendationId: String!) {
    recordRecommendationViewed(recommendationId: $recommendationId)
  }
`);

const ViewRecommendationScreen: React.FC<
  StackScreenProps<RootStackParamList, "ViewRecommendation">
> = ({ route, navigation }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      detachPreviousScreen: false,
    });
  }, [navigation]);
  const queryClient = useQueryClient();
  const { graphqlRequest } = useAuth();
  const auth = useAuth();
  const recommendationId = route.params.recommendationId;
  const { data, refetch } = useQuery({
    queryKey: ["recommendation", recommendationId],
    queryFn: async () =>
      graphqlRequest(getRecommendationDocument, { recommendationId }),
  });
  const saveRecommendationMutation = useMutation(
    async () =>
      graphqlRequest(saveRecommendationDocument, { recommendationId }),
    {
      onSuccess: () => {
        refetch();
        queryClient.invalidateQueries({
          queryKey: ["savedRecommendations"],
        });
      },
    }
  );
  const { mutate: recordView } = useMutation(
    async () =>
      graphqlRequest(recordViewedRecommendationDocument, { recommendationId }),
    {}
  );
  useEffect(() => recordView(), [recordView]);
  const deleteMutation = useMutation(
    async () =>
      graphqlRequest(deleteRecommendationDocument, {
        recommendationId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        navigation.goBack();
      },
    }
  );
  if (!data) {
    return <ActivityIndicator />;
  }
  const createdByMe =
    auth.user?.type == "authenticated" &&
    data.getRecommendation.createdByUser.id === auth.user.userId;
  return (
    <ScrollView
      className="flex min-h-0 w-full flex-shrink flex-grow basis-0 items-center justify-start space-y-4 p-8"
      contentContainerStyle={{
        // @ts-ignore
        gap: 8,
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <TouchableOpacity
        onPress={() =>
          navigation.push("Location", {
            locationId: data.getRecommendation.location.id,
          })
        }
      >
        <Text className="text-2xl font-bold uppercase text-zinc-200">
          {`${data.getRecommendation.location.name}`}
        </Text>
        <Text className="text-sm text-zinc-400">
          {data.getRecommendation.location.formattedAddress}
        </Text>
        <View className="flex-row items-center space-x-1">
          <Text className="text-sm text-blue-400">more details</Text>
          <Ionicons name="information-circle" color={colors.blue[400]} />
        </View>
      </TouchableOpacity>
      <View className="flex w-full flex-row items-center justify-between">
        <View className="space-y-2 py-4">
          <Text className="text-zinc-400">
            {`${timeSince(
              new Date(data.getRecommendation.createdAt + "Z")
            )} ago`}
          </Text>
          <Text className="text-zinc-400">{`recommended by`}</Text>
          <TouchableOpacity
            className="flex-row items-center space-x-4"
            onPress={() =>
              navigation.push("UserProfile", {
                userId: data.getRecommendation.createdByUser.id,
              })
            }
          >
            <ProfilePhoto
              imageUri={data.getRecommendation.createdByUser.profilePhoto?.url}
            />
            <View>
              {data.getRecommendation.createdByUser.fullName != null && (
                <Text className="text-xl text-zinc-200">
                  {data.getRecommendation.createdByUser.fullName}
                </Text>
              )}
              <Text className="text-zinc-200">{`@${data.getRecommendation.createdByUser.username}`}</Text>
            </View>
          </TouchableOpacity>
        </View>
        {!createdByMe &&
          (data.getRecommendation.isSaved ? (
            <Ionicons
              name="bookmark"
              size={30}
              color={colors.zinc[200]}
            ></Ionicons>
          ) : (
            <TouchableOpacity
              disabled={saveRecommendationMutation.isLoading}
              onPress={() => saveRecommendationMutation.mutate()}
            >
              <Ionicons
                name="bookmark-outline"
                size={30}
                color={colors.zinc[200]}
              ></Ionicons>
            </TouchableOpacity>
          ))}
      </View>
      <Text className="w-full rounded bg-zinc-700 p-4 py-8 text-base text-white">
        {data.getRecommendation.description}
      </Text>
      {createdByMe && (
        <>
          <TouchableOpacity
            className="mt-4 w-full rounded bg-zinc-700 py-4"
            onPress={() =>
              navigation.push("EditRecommendation", { recommendationId })
            }
          >
            <Text className="text-center text-zinc-200">
              {"Edit Recommendation"}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            className="w-full rounded bg-zinc-700 py-4"
            onPress={() => {
              if (Platform.OS == "web") {
                if (window.confirm("Are you sure?\nthis action is permanent")) {
                  deleteMutation.mutate();
                }
              } else {
                Alert.alert("Are you sure?", "this action is permanent", [
                  { text: "Cancel", style: "cancel" },
                  {
                    text: "DELETE",
                    style: "destructive",
                    onPress: () => deleteMutation.mutate(),
                  },
                ]);
              }
            }}
          >
            <Text className="text-center text-red-600">
              {"Delete Recommendation"}
            </Text>
          </TouchableOpacity>
        </>
      )}
    </ScrollView>
  );
};

export default ViewRecommendationScreen;
