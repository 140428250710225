import {
  View,
  useWindowDimensions,
  TouchableOpacity,
  Text,
  TextInput,
} from "react-native";
import React, { useState } from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import ProfilePictureUpload from "../components/profile-picture-upload";
import { useAuth } from "../hooks/auth";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { setBioDocument, setFullNameDocument } from "../queries/profile";

const OnboardingScreen: React.FC<
  NativeStackScreenProps<RootStackParamList, "Onboarding">
> = ({ navigation }) => {
  const { graphqlRequest } = useAuth();
  const queryClient = useQueryClient();
  let [page, setPage] = useState(0);
  const [fullName, setFullName] = useState("");
  const setFullNameMutation = useMutation(async (fullName: string | null) =>
    graphqlRequest(setFullNameDocument, { fullName })
  );
  const [bio, setBio] = useState("");
  const setBioMutation = useMutation(async (bio: string | null) =>
    graphqlRequest(setBioDocument, { bio })
  );
  const numPages = 3;
  return (
    <View className="flex w-full flex-1 items-center justify-center gap-4">
      {page == 0 && (
        <>
          <Text className="text-lg text-zinc-200">Choose a profile photo</Text>
          <ProfilePictureUpload />
        </>
      )}
      {page == 1 && (
        <>
          <Text className="text-lg text-zinc-200">Add your name</Text>
          <Text className="text-center text-sm text-zinc-400">
            adding your name will help people find your profile in searches
          </Text>
          <TextInput
            className="w-[80%] rounded-l bg-zinc-700 p-4 text-zinc-200"
            value={fullName}
            onChangeText={setFullName}
          />
        </>
      )}
      {page == 2 && (
        <>
          <Text className="text-lg text-zinc-200">Write a short bio</Text>
          <Text className="text-sm text-zinc-400">
            If you want a description of yourself on your profile
          </Text>
          <TextInput
            className="w-[80%] rounded-l bg-zinc-700 p-4 text-zinc-200"
            value={bio}
            onChangeText={setBio}
            numberOfLines={3}
            multiline
          />
        </>
      )}
      <View className="flex-row items-center justify-center gap-4">
        {page > 0 && (
          <TouchableOpacity
            className="rounded bg-zinc-200 p-4"
            onPress={() => setPage((p) => p - 1)}
          >
            <Text className="text-zinc-900">Prev</Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          className="rounded bg-zinc-200 p-4"
          onPress={() => {
            if (page == 0) {
              setPage((p) => p + 1);
            }
            if (page == 1) {
              setFullNameMutation.mutate(fullName);
              setPage((p) => p + 1);
            }
            if (page == 2) {
              setBioMutation.mutate(bio);
              navigation.navigate("Tabs");
            }
          }}
        >
          <Text className="text-zinc-900">Next</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default OnboardingScreen;
