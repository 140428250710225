import { graphql } from "../gql";

export const getRecommendationDocument = graphql(`
  query getRecommendation($recommendationId: String!) {
    getRecommendation(recommendationId: $recommendationId) {
      id
      description
      createdByUser {
        id
        fullName
        username
        profilePhoto {
          url(width: 120, height: 120)
        }
      }
      isSaved
      createdAt
      location {
        id
        name
        formattedAddress
        geoLocation {
          latitude
          longitude
        }
        googleMapsPlaceId
        googleMapsUrl
      }
    }
  }
`);

export const saveRecommendationDocument = graphql(`
  mutation saveRecommendation($recommendationId: String!) {
    saveRecommendation(recommendationId: $recommendationId)
  }
`);

export const removeRecommendationFromSavedDocument = graphql(`
  mutation removeRecommendationFromSaved($recommendationId: String!) {
    removeRecommendationFromSaved(recommendationId: $recommendationId)
  }
`);
