import React, { useState } from "react";
import { Modal, Pressable, TouchableOpacity, View } from "react-native";

export type Props = {
  buttonContent: React.ReactNode;
  modalContent: React.ReactNode;
};
const ClickToOpenModal: React.FC<Props> = ({ buttonContent, modalContent }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TouchableOpacity onPress={() => setIsOpen(true)}>
        {buttonContent}
      </TouchableOpacity>
      <Modal transparent={true} visible={isOpen}>
        <Pressable
          onPress={() => setIsOpen(false)}
          className="flex-1 items-center justify-center bg-black bg-opacity-70"
        >
          {modalContent}
        </Pressable>
      </Modal>
    </>
  );
};
export default ClickToOpenModal;
