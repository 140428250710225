import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { graphql } from "../gql";
import type { DrawerScreenProps } from "@react-navigation/drawer";
import { useQuery } from "@tanstack/react-query";
import RecommendationTile from "../components/recommendation-tile";
import { useAuth } from "../hooks/auth";
import ProfilePhoto from "../components/profile-photo";
import { FlashList } from "@shopify/flash-list";
import colors from "tailwindcss/colors";

const meDocument = graphql(`
  query me {
    getMe {
      id
      username
      fullName
      bio
      followingCount
      followerCount
      profilePhoto {
        smallUrl: url(width: 120, height: 120)
      }
      recommendations {
        id
        description
        createdAt
        createdByUser {
          fullName
          username
        }
        location {
          name
          geoLocation {
            latitude
            longitude
          }
          locality
          googleMapsPlaceId
          googleMapsUrl
        }
      }
    }
  }
`);

const ProfileScreen: React.FC<
  DrawerScreenProps<RootStackParamList, "Profile">
> = ({ navigation }) => {
  const { graphqlRequest } = useAuth();
  const { data } = useQuery({
    queryKey: ["me"],
    queryFn: async () => graphqlRequest(meDocument),
  });
  if (!data) {
    return <ActivityIndicator />;
  }
  return (
    <View className="flex-1 items-center justify-center space-y-4 py-4">
      <View className="w-full flex-row items-center space-x-5 px-4">
        <TouchableOpacity
          onPress={() => navigation.navigate("ChangeProfilePicture")}
        >
          <ProfilePhoto imageUri={data.getMe.profilePhoto?.smallUrl} />
        </TouchableOpacity>
        <View>
          {data.getMe.fullName != null && (
            <Text className="text-xl text-zinc-200">{data.getMe.fullName}</Text>
          )}
          <Text className="text-zinc-200">{`@${data.getMe.username}`}</Text>
        </View>
        <Text className="text-md text-zinc-200">{data.getMe.bio ?? ""}</Text>
      </View>
      <View className="w-full flex-row items-center justify-center space-x-5 px-4">
        <TouchableOpacity
          className="rounded bg-zinc-600 p-2"
          onPress={() => navigation.navigate("EditProfile")}
        >
          <Text className="text-zinc-200">Edit Profile</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("Followers", { userId: data.getMe.id })
          }
        >
          <Text className="text-center text-zinc-200">
            {data.getMe.followerCount}
          </Text>
          <Text className="text-center text-zinc-200">Followers</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("Following", { userId: data.getMe.id })
          }
        >
          <Text className="text-center text-zinc-200">
            {data.getMe.followingCount}
          </Text>
          <Text className="text-center text-zinc-200">Following</Text>
        </TouchableOpacity>
      </View>
      <View className="flex min-h-0 w-full shrink grow basis-0">
        <View
          style={{
            borderBottomColor: colors.zinc[600],
            borderBottomWidth: StyleSheet.hairlineWidth,
          }}
        />
        <FlashList
          contentContainerStyle={{ padding: 20 }}
          data={data.getMe.recommendations}
          keyExtractor={(item) => item.id}
          ItemSeparatorComponent={() => (
            <View
              style={{
                borderBottomColor: colors.zinc[600],
                borderBottomWidth: StyleSheet.hairlineWidth,
              }}
            />
          )}
          renderItem={({ item }) => (
            <View className="px-4 py-1">
              <RecommendationTile
                recommendation={item}
                onPress={({ recommendationId }) =>
                  navigation.navigate("ViewRecommendation", {
                    recommendationId,
                  })
                }
              />
            </View>
          )}
        />
      </View>
    </View>
  );
};

export default ProfileScreen;
