import { View, Text } from "react-native";
import React, { useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import PhoneInput from "../components/phone-input";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useApi } from "../hooks/api";
import { showErrorToast } from "../components/error-toast";
import { useAuth } from "../hooks/auth";

const SendUserInviteScreen = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(false);
  const api = useApi();
  const auth = useAuth();
  const handleSubmit = () => {
    if (!isValid) {
      showErrorToast("phone number is not valid");
      return;
    }
    api
      .sendUserInvite({
        accessToken: auth.user?.accessToken!,
        phoneNumber: phoneNumber,
        shouldResend: false,
      })
      .then(() => showErrorToast("invite sent"))
      .catch((err) =>
        err.error === "conflict"
          ? showErrorToast("that phone number is already invited")
          : showErrorToast(err.description)
      );
  };
  return (
    <SafeAreaView className="h-full w-full">
      <View className="flex h-full w-full flex-col items-center justify-center gap-4">
        <Text className="text-zinc-200">Send an invite text message</Text>
        <PhoneInput
          onPhoneNumberChange={(phoneNumber, isValid) => {
            setPhoneNumber(phoneNumber);
            setIsValid(isValid);
          }}
        />
        <TouchableOpacity
          className="rounded bg-zinc-200 p-4"
          onPress={handleSubmit}
        >
          <Text className="text-zinc-800">Send</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

export default SendUserInviteScreen;
