import { ActivityIndicator, StyleSheet, Text, View } from "react-native";
import { graphql } from "../gql";
import { useQuery } from "@tanstack/react-query";
import RecommendationTile from "../components/recommendation-tile";
import { useAuth } from "../hooks/auth";
import ProfilePhoto from "../components/profile-photo";
import ClickToOpenModal from "../components/click-to-open-modal";
import { TouchableOpacity } from "react-native-gesture-handler";
import FollowUnfollowButton from "../components/follow-unfollow-button";
import { FlashList } from "@shopify/flash-list";
import { StackScreenProps } from "@react-navigation/stack";
import colors from "tailwindcss/colors";
import { useLayoutEffect } from "react";

const userDocument = graphql(`
  query user($userId: String!) {
    getUser(userId: $userId) {
      id
      username
      fullName
      bio
      followerCount
      followingCount
      iFollow
      profilePhoto {
        smallUrl: url(width: 120, height: 120)
        largeUrl: url(width: 1200, height: 1200)
      }
      recommendations {
        id
        description
        createdByUser {
          fullName
          username
        }
        location {
          name
          geoLocation {
            latitude
            longitude
          }
          locality
          googleMapsPlaceId
          googleMapsUrl
        }
        createdAt
      }
    }
  }
`);

const UserProfileScreen: React.FC<
  StackScreenProps<RootStackParamList, "UserProfile">
> = ({ route, navigation }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      detachPreviousScreen: false,
    });
  }, [navigation]);
  const { graphqlRequest } = useAuth();
  const userId = route.params.userId;
  const { data } = useQuery({
    queryKey: ["user", userId],
    queryFn: async () => graphqlRequest(userDocument, { userId }),
  });
  if (!data) {
    return <ActivityIndicator />;
  }
  return (
    <View className="flex-1 items-center justify-center space-y-4 py-4">
      <View className="w-full flex-row items-center space-x-5 px-4">
        <ClickToOpenModal
          buttonContent={
            <ProfilePhoto imageUri={data.getUser.profilePhoto?.smallUrl} />
          }
          modalContent={
            <ProfilePhoto
              size={600}
              imageUri={data.getUser.profilePhoto?.largeUrl}
            />
          }
        />
        <View>
          {data.getUser.fullName != null && (
            <Text className="text-xl text-zinc-200">
              {data.getUser.fullName}
            </Text>
          )}
          <Text className="text-zinc-200">{`@${data.getUser.username}`}</Text>
        </View>
        <Text className="text-md text-zinc-200">{data.getUser.bio ?? ""}</Text>
      </View>
      <View className="w-full flex-row items-center justify-center space-x-5 px-4">
        <TouchableOpacity
          onPress={() => navigation.navigate("Followers", { userId })}
        >
          <Text className="text-center text-zinc-200">
            {data.getUser.followerCount}
          </Text>
          <Text className="text-center text-zinc-200">Followers</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => navigation.navigate("Following", { userId })}
        >
          <Text className="text-center text-zinc-200">
            {data.getUser.followingCount}
          </Text>
          <Text className="text-center text-zinc-200">Following</Text>
        </TouchableOpacity>
        <FollowUnfollowButton
          userId={data.getUser.id}
          iFollow={data.getUser.iFollow}
          buttonClassName="rounded bg-zinc-600 p-4"
          textClassName="text-zinc-200"
        />
      </View>
      <View className="flex min-h-0 w-full shrink grow basis-0">
        <View
          style={{
            borderBottomColor: colors.zinc[600],
            borderBottomWidth: StyleSheet.hairlineWidth,
          }}
        />
        <FlashList
          contentContainerStyle={{ padding: 20 }}
          data={data.getUser.recommendations}
          keyExtractor={(item) => item.id}
          ItemSeparatorComponent={() => (
            <View
              style={{
                borderBottomColor: colors.zinc[600],
                borderBottomWidth: StyleSheet.hairlineWidth,
              }}
            />
          )}
          renderItem={({ item }) => (
            <View className="px-4 py-1">
              <RecommendationTile
                recommendation={item}
                onPress={({ recommendationId }) =>
                  navigation.push("ViewRecommendation", {
                    recommendationId,
                  })
                }
              />
            </View>
          )}
        />
      </View>
    </View>
  );
};

export default UserProfileScreen;
