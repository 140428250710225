import { Text, TouchableOpacity, View } from "react-native";
import { graphql } from "../gql";
import type { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import TextInput from "../components/text-input";
import { useState } from "react";
import { useAuth } from "../hooks/auth";
import { setBioDocument } from "../queries/profile";


const SetBioScreen: React.FC<
  BottomTabScreenProps<RootStackParamList, "SetBio">
> = ({ navigation, route }) => {
  const { graphqlRequest } = useAuth();
  const queryClient = useQueryClient();
  const [bio, setBio] = useState(route.params.bio);
  const setBioMutation = useMutation(
    async (bio: string | null) => graphqlRequest(setBioDocument, { bio }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        navigation.goBack();
      },
    }
  );
  return (
    <View className="w-full flex-1 flex-col space-y-2 p-8">
      <TextInput
        className="w-full rounded bg-zinc-700 p-4 text-zinc-200"
        value={bio ?? undefined}
        onChangeText={setBio}
        multiline={true}
        numberOfLines={3}
      />
      <TouchableOpacity
        className="w-full items-center justify-center rounded bg-zinc-400 p-4"
        onPress={() => setBioMutation.mutate(bio)}
      >
        <Text className="text-zinc-900">Done</Text>
      </TouchableOpacity>
    </View>
  );
};

export default SetBioScreen;
