/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  query getLocationAutocompleteSuggestions(\n    $query: String!\n    $sessionToken: String\n    $userLocation: Coordinate\n  ) {\n    getLocationAutocompleteSuggestions(\n      query: $query\n      sessionToken: $sessionToken\n      userLocation: $userLocation\n    ) {\n      predictions {\n        placeId\n        description\n      }\n      sessionToken\n    }\n  }\n": types.GetLocationAutocompleteSuggestionsDocument,
    "\n  query selectFromAutocomplete($placeId: String!, $sessionToken: String!) {\n    selectLocationFromAutocomplete(\n      placeId: $placeId\n      sessionToken: $sessionToken\n    ) {\n      id\n      name\n      formattedAddress\n      geoLocation {\n        latitude\n        longitude\n      }\n      googleMapsPlaceId\n      googleMapsUrl\n    }\n  }\n": types.SelectFromAutocompleteDocument,
    "\n  mutation uploadProfilePhoto($file: Upload!) {\n    uploadProfilePhoto(file: $file)\n  }\n": types.UploadProfilePhotoDocument,
    "\n  query getProfilePhoto {\n    getMe {\n      profilePhoto {\n        largeUrl: url(width: 1200, height: 1200)\n      }\n    }\n  }\n": types.GetProfilePhotoDocument,
    "\n  mutation followUser($userId: String!) {\n    followUser(userId: $userId)\n  }\n": types.FollowUserDocument,
    "\n  mutation unfollowUser($userId: String!) {\n    unfollowUser(userId: $userId)\n  }\n": types.UnfollowUserDocument,
    "\n  fragment FollowerFollowingUserListFragment on User {\n    id\n    username\n    fullName\n    profilePhoto {\n      smallUrl: url(width: 120, height: 120)\n    }\n    iFollow\n    followsMe\n  }\n": types.FollowerFollowingUserListFragmentFragmentDoc,
    "\n  query nearby($userLocation: Coordinate!) {\n    getNearbyLocations(userLocation: $userLocation, first: 50) {\n      edges {\n        node {\n          id\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          recommendationCount\n          recommendationsConnection(first: 1) {\n            nodes {\n              createdByUser {\n                username\n              }\n            }\n          }\n        }\n        distanceMeters\n      }\n    }\n  }\n": types.NearbyDocument,
    "\n  query locationsInArea($bounds: GeoAreaBounds!) {\n    getLocationsInArea(bounds: $bounds, first: 500) {\n      edges {\n        node {\n          id\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          recommendationCount\n        }\n        distanceMeters\n      }\n    }\n  }\n": types.LocationsInAreaDocument,
    "\n  mutation setFullName($fullName: String) {\n    setFullName(fullName: $fullName)\n  }\n": types.SetFullNameDocument,
    "\n  mutation setBio($bio: String) {\n    setBio(bio: $bio)\n  }\n": types.SetBioDocument,
    "\n  query getRecommendation($recommendationId: String!) {\n    getRecommendation(recommendationId: $recommendationId) {\n      id\n      description\n      createdByUser {\n        id\n        fullName\n        username\n        profilePhoto {\n          url(width: 120, height: 120)\n        }\n      }\n      isSaved\n      createdAt\n      location {\n        id\n        name\n        formattedAddress\n        geoLocation {\n          latitude\n          longitude\n        }\n        googleMapsPlaceId\n        googleMapsUrl\n      }\n    }\n  }\n": types.GetRecommendationDocument,
    "\n  mutation saveRecommendation($recommendationId: String!) {\n    saveRecommendation(recommendationId: $recommendationId)\n  }\n": types.SaveRecommendationDocument,
    "\n  mutation removeRecommendationFromSaved($recommendationId: String!) {\n    removeRecommendationFromSaved(recommendationId: $recommendationId)\n  }\n": types.RemoveRecommendationFromSavedDocument,
    "\n  mutation changeUsername($username: String!) {\n    changeUsername(username: $username)\n  }\n": types.ChangeUsernameDocument,
    "\n  query editableProfileFields {\n    getMe {\n      username\n      fullName\n      profilePhoto {\n        url(width: 120, height: 120)\n      }\n      bio\n    }\n  }\n": types.EditableProfileFieldsDocument,
    "\n  mutation editRecommendation(\n    $recommendationId: String!\n    $description: String\n  ) {\n    editRecommendation(\n      recommendationId: $recommendationId\n      description: $description\n    ) {\n      id\n    }\n  }\n": types.EditRecommendationDocument,
    "\n  query getFollowers($userId: String!) {\n    getUser(userId: $userId) {\n      followers {\n        ...FollowerFollowingUserListFragment\n      }\n    }\n  }\n": types.GetFollowersDocument,
    "\n  query getFollowing($userId: String!) {\n    getUser(userId: $userId) {\n      following {\n        ...FollowerFollowingUserListFragment\n      }\n    }\n  }\n": types.GetFollowingDocument,
    "\n  query homeFeed($after: String) {\n    homeFeed(first: 20, after: $after) {\n      nodes {\n        __typename\n        ... on UsersToFollow {\n          users {\n            id\n            username\n            fullName\n            profilePhoto {\n              smallUrl: url(width: 120, height: 120)\n            }\n            followsMe\n            iFollow\n          }\n        }\n        ... on Recommendation {\n          id\n          location {\n            name\n            locality\n          }\n          createdByUser {\n            fullName\n            username\n            profilePhoto {\n              url(width: 60, height: 60)\n            }\n          }\n          description\n          createdAt\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.HomeFeedDocument,
    "\n  query location($locationId: String!, $width: Int!) {\n    getLocation(locationId: $locationId) {\n      name\n      formattedAddress\n      googleMapsUrl\n      website\n      phoneNumber\n      staticMapUrl(dimensions: { width: $width, height: 200 })\n      recommendations {\n        id\n        createdByUser {\n          fullName\n          username\n          profilePhoto {\n            url(width: 60, height: 60)\n          }\n        }\n        description\n        createdAt\n      }\n    }\n  }\n": types.LocationDocument,
    "\n  query me {\n    getMe {\n      id\n      username\n      fullName\n      bio\n      followingCount\n      followerCount\n      profilePhoto {\n        smallUrl: url(width: 120, height: 120)\n      }\n      recommendations {\n        id\n        description\n        createdAt\n        createdByUser {\n          fullName\n          username\n        }\n        location {\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          locality\n          googleMapsPlaceId\n          googleMapsUrl\n        }\n      }\n    }\n  }\n": types.MeDocument,
    "\n  mutation createRecommendation($description: String!, $locationId: String!) {\n    createRecommendation(description: $description, locationId: $locationId) {\n      id\n    }\n  }\n": types.CreateRecommendationDocument,
    "\n  query getSavedRecommendations {\n    getMe {\n      savedRecommendations {\n        id\n        description\n        createdAt\n        createdByUser {\n          fullName\n          username\n        }\n        location {\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          googleMapsPlaceId\n          googleMapsUrl\n        }\n      }\n    }\n  }\n": types.GetSavedRecommendationsDocument,
    "\n  query search($query: String!) {\n    search(query: $query) {\n      __typename\n      ... on User {\n        id\n        username\n        fullName\n      }\n      ... on Recommendation {\n        id\n        location {\n          name\n        }\n        createdByUser {\n          username\n          fullName\n        }\n        description\n      }\n      ... on Location {\n        id\n        name\n        formattedAddress\n      }\n    }\n  }\n": types.SearchDocument,
    "\n  query user($userId: String!) {\n    getUser(userId: $userId) {\n      id\n      username\n      fullName\n      bio\n      followerCount\n      followingCount\n      iFollow\n      profilePhoto {\n        smallUrl: url(width: 120, height: 120)\n        largeUrl: url(width: 1200, height: 1200)\n      }\n      recommendations {\n        id\n        description\n        createdByUser {\n          fullName\n          username\n        }\n        location {\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          locality\n          googleMapsPlaceId\n          googleMapsUrl\n        }\n        createdAt\n      }\n    }\n  }\n": types.UserDocument,
    "\n  mutation deleteRecommendation($recommendationId: String!) {\n    deleteRecommendation(recommendationId: $recommendationId)\n  }\n": types.DeleteRecommendationDocument,
    "\n  mutation recordRecommendationViewed($recommendationId: String!) {\n    recordRecommendationViewed(recommendationId: $recommendationId)\n  }\n": types.RecordRecommendationViewedDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getLocationAutocompleteSuggestions(\n    $query: String!\n    $sessionToken: String\n    $userLocation: Coordinate\n  ) {\n    getLocationAutocompleteSuggestions(\n      query: $query\n      sessionToken: $sessionToken\n      userLocation: $userLocation\n    ) {\n      predictions {\n        placeId\n        description\n      }\n      sessionToken\n    }\n  }\n"): (typeof documents)["\n  query getLocationAutocompleteSuggestions(\n    $query: String!\n    $sessionToken: String\n    $userLocation: Coordinate\n  ) {\n    getLocationAutocompleteSuggestions(\n      query: $query\n      sessionToken: $sessionToken\n      userLocation: $userLocation\n    ) {\n      predictions {\n        placeId\n        description\n      }\n      sessionToken\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query selectFromAutocomplete($placeId: String!, $sessionToken: String!) {\n    selectLocationFromAutocomplete(\n      placeId: $placeId\n      sessionToken: $sessionToken\n    ) {\n      id\n      name\n      formattedAddress\n      geoLocation {\n        latitude\n        longitude\n      }\n      googleMapsPlaceId\n      googleMapsUrl\n    }\n  }\n"): (typeof documents)["\n  query selectFromAutocomplete($placeId: String!, $sessionToken: String!) {\n    selectLocationFromAutocomplete(\n      placeId: $placeId\n      sessionToken: $sessionToken\n    ) {\n      id\n      name\n      formattedAddress\n      geoLocation {\n        latitude\n        longitude\n      }\n      googleMapsPlaceId\n      googleMapsUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation uploadProfilePhoto($file: Upload!) {\n    uploadProfilePhoto(file: $file)\n  }\n"): (typeof documents)["\n  mutation uploadProfilePhoto($file: Upload!) {\n    uploadProfilePhoto(file: $file)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getProfilePhoto {\n    getMe {\n      profilePhoto {\n        largeUrl: url(width: 1200, height: 1200)\n      }\n    }\n  }\n"): (typeof documents)["\n  query getProfilePhoto {\n    getMe {\n      profilePhoto {\n        largeUrl: url(width: 1200, height: 1200)\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation followUser($userId: String!) {\n    followUser(userId: $userId)\n  }\n"): (typeof documents)["\n  mutation followUser($userId: String!) {\n    followUser(userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation unfollowUser($userId: String!) {\n    unfollowUser(userId: $userId)\n  }\n"): (typeof documents)["\n  mutation unfollowUser($userId: String!) {\n    unfollowUser(userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment FollowerFollowingUserListFragment on User {\n    id\n    username\n    fullName\n    profilePhoto {\n      smallUrl: url(width: 120, height: 120)\n    }\n    iFollow\n    followsMe\n  }\n"): (typeof documents)["\n  fragment FollowerFollowingUserListFragment on User {\n    id\n    username\n    fullName\n    profilePhoto {\n      smallUrl: url(width: 120, height: 120)\n    }\n    iFollow\n    followsMe\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query nearby($userLocation: Coordinate!) {\n    getNearbyLocations(userLocation: $userLocation, first: 50) {\n      edges {\n        node {\n          id\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          recommendationCount\n          recommendationsConnection(first: 1) {\n            nodes {\n              createdByUser {\n                username\n              }\n            }\n          }\n        }\n        distanceMeters\n      }\n    }\n  }\n"): (typeof documents)["\n  query nearby($userLocation: Coordinate!) {\n    getNearbyLocations(userLocation: $userLocation, first: 50) {\n      edges {\n        node {\n          id\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          recommendationCount\n          recommendationsConnection(first: 1) {\n            nodes {\n              createdByUser {\n                username\n              }\n            }\n          }\n        }\n        distanceMeters\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query locationsInArea($bounds: GeoAreaBounds!) {\n    getLocationsInArea(bounds: $bounds, first: 500) {\n      edges {\n        node {\n          id\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          recommendationCount\n        }\n        distanceMeters\n      }\n    }\n  }\n"): (typeof documents)["\n  query locationsInArea($bounds: GeoAreaBounds!) {\n    getLocationsInArea(bounds: $bounds, first: 500) {\n      edges {\n        node {\n          id\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          recommendationCount\n        }\n        distanceMeters\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation setFullName($fullName: String) {\n    setFullName(fullName: $fullName)\n  }\n"): (typeof documents)["\n  mutation setFullName($fullName: String) {\n    setFullName(fullName: $fullName)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation setBio($bio: String) {\n    setBio(bio: $bio)\n  }\n"): (typeof documents)["\n  mutation setBio($bio: String) {\n    setBio(bio: $bio)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getRecommendation($recommendationId: String!) {\n    getRecommendation(recommendationId: $recommendationId) {\n      id\n      description\n      createdByUser {\n        id\n        fullName\n        username\n        profilePhoto {\n          url(width: 120, height: 120)\n        }\n      }\n      isSaved\n      createdAt\n      location {\n        id\n        name\n        formattedAddress\n        geoLocation {\n          latitude\n          longitude\n        }\n        googleMapsPlaceId\n        googleMapsUrl\n      }\n    }\n  }\n"): (typeof documents)["\n  query getRecommendation($recommendationId: String!) {\n    getRecommendation(recommendationId: $recommendationId) {\n      id\n      description\n      createdByUser {\n        id\n        fullName\n        username\n        profilePhoto {\n          url(width: 120, height: 120)\n        }\n      }\n      isSaved\n      createdAt\n      location {\n        id\n        name\n        formattedAddress\n        geoLocation {\n          latitude\n          longitude\n        }\n        googleMapsPlaceId\n        googleMapsUrl\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation saveRecommendation($recommendationId: String!) {\n    saveRecommendation(recommendationId: $recommendationId)\n  }\n"): (typeof documents)["\n  mutation saveRecommendation($recommendationId: String!) {\n    saveRecommendation(recommendationId: $recommendationId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation removeRecommendationFromSaved($recommendationId: String!) {\n    removeRecommendationFromSaved(recommendationId: $recommendationId)\n  }\n"): (typeof documents)["\n  mutation removeRecommendationFromSaved($recommendationId: String!) {\n    removeRecommendationFromSaved(recommendationId: $recommendationId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation changeUsername($username: String!) {\n    changeUsername(username: $username)\n  }\n"): (typeof documents)["\n  mutation changeUsername($username: String!) {\n    changeUsername(username: $username)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query editableProfileFields {\n    getMe {\n      username\n      fullName\n      profilePhoto {\n        url(width: 120, height: 120)\n      }\n      bio\n    }\n  }\n"): (typeof documents)["\n  query editableProfileFields {\n    getMe {\n      username\n      fullName\n      profilePhoto {\n        url(width: 120, height: 120)\n      }\n      bio\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation editRecommendation(\n    $recommendationId: String!\n    $description: String\n  ) {\n    editRecommendation(\n      recommendationId: $recommendationId\n      description: $description\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation editRecommendation(\n    $recommendationId: String!\n    $description: String\n  ) {\n    editRecommendation(\n      recommendationId: $recommendationId\n      description: $description\n    ) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getFollowers($userId: String!) {\n    getUser(userId: $userId) {\n      followers {\n        ...FollowerFollowingUserListFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query getFollowers($userId: String!) {\n    getUser(userId: $userId) {\n      followers {\n        ...FollowerFollowingUserListFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getFollowing($userId: String!) {\n    getUser(userId: $userId) {\n      following {\n        ...FollowerFollowingUserListFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query getFollowing($userId: String!) {\n    getUser(userId: $userId) {\n      following {\n        ...FollowerFollowingUserListFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query homeFeed($after: String) {\n    homeFeed(first: 20, after: $after) {\n      nodes {\n        __typename\n        ... on UsersToFollow {\n          users {\n            id\n            username\n            fullName\n            profilePhoto {\n              smallUrl: url(width: 120, height: 120)\n            }\n            followsMe\n            iFollow\n          }\n        }\n        ... on Recommendation {\n          id\n          location {\n            name\n            locality\n          }\n          createdByUser {\n            fullName\n            username\n            profilePhoto {\n              url(width: 60, height: 60)\n            }\n          }\n          description\n          createdAt\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query homeFeed($after: String) {\n    homeFeed(first: 20, after: $after) {\n      nodes {\n        __typename\n        ... on UsersToFollow {\n          users {\n            id\n            username\n            fullName\n            profilePhoto {\n              smallUrl: url(width: 120, height: 120)\n            }\n            followsMe\n            iFollow\n          }\n        }\n        ... on Recommendation {\n          id\n          location {\n            name\n            locality\n          }\n          createdByUser {\n            fullName\n            username\n            profilePhoto {\n              url(width: 60, height: 60)\n            }\n          }\n          description\n          createdAt\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query location($locationId: String!, $width: Int!) {\n    getLocation(locationId: $locationId) {\n      name\n      formattedAddress\n      googleMapsUrl\n      website\n      phoneNumber\n      staticMapUrl(dimensions: { width: $width, height: 200 })\n      recommendations {\n        id\n        createdByUser {\n          fullName\n          username\n          profilePhoto {\n            url(width: 60, height: 60)\n          }\n        }\n        description\n        createdAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query location($locationId: String!, $width: Int!) {\n    getLocation(locationId: $locationId) {\n      name\n      formattedAddress\n      googleMapsUrl\n      website\n      phoneNumber\n      staticMapUrl(dimensions: { width: $width, height: 200 })\n      recommendations {\n        id\n        createdByUser {\n          fullName\n          username\n          profilePhoto {\n            url(width: 60, height: 60)\n          }\n        }\n        description\n        createdAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query me {\n    getMe {\n      id\n      username\n      fullName\n      bio\n      followingCount\n      followerCount\n      profilePhoto {\n        smallUrl: url(width: 120, height: 120)\n      }\n      recommendations {\n        id\n        description\n        createdAt\n        createdByUser {\n          fullName\n          username\n        }\n        location {\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          locality\n          googleMapsPlaceId\n          googleMapsUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query me {\n    getMe {\n      id\n      username\n      fullName\n      bio\n      followingCount\n      followerCount\n      profilePhoto {\n        smallUrl: url(width: 120, height: 120)\n      }\n      recommendations {\n        id\n        description\n        createdAt\n        createdByUser {\n          fullName\n          username\n        }\n        location {\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          locality\n          googleMapsPlaceId\n          googleMapsUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createRecommendation($description: String!, $locationId: String!) {\n    createRecommendation(description: $description, locationId: $locationId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation createRecommendation($description: String!, $locationId: String!) {\n    createRecommendation(description: $description, locationId: $locationId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getSavedRecommendations {\n    getMe {\n      savedRecommendations {\n        id\n        description\n        createdAt\n        createdByUser {\n          fullName\n          username\n        }\n        location {\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          googleMapsPlaceId\n          googleMapsUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getSavedRecommendations {\n    getMe {\n      savedRecommendations {\n        id\n        description\n        createdAt\n        createdByUser {\n          fullName\n          username\n        }\n        location {\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          googleMapsPlaceId\n          googleMapsUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query search($query: String!) {\n    search(query: $query) {\n      __typename\n      ... on User {\n        id\n        username\n        fullName\n      }\n      ... on Recommendation {\n        id\n        location {\n          name\n        }\n        createdByUser {\n          username\n          fullName\n        }\n        description\n      }\n      ... on Location {\n        id\n        name\n        formattedAddress\n      }\n    }\n  }\n"): (typeof documents)["\n  query search($query: String!) {\n    search(query: $query) {\n      __typename\n      ... on User {\n        id\n        username\n        fullName\n      }\n      ... on Recommendation {\n        id\n        location {\n          name\n        }\n        createdByUser {\n          username\n          fullName\n        }\n        description\n      }\n      ... on Location {\n        id\n        name\n        formattedAddress\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query user($userId: String!) {\n    getUser(userId: $userId) {\n      id\n      username\n      fullName\n      bio\n      followerCount\n      followingCount\n      iFollow\n      profilePhoto {\n        smallUrl: url(width: 120, height: 120)\n        largeUrl: url(width: 1200, height: 1200)\n      }\n      recommendations {\n        id\n        description\n        createdByUser {\n          fullName\n          username\n        }\n        location {\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          locality\n          googleMapsPlaceId\n          googleMapsUrl\n        }\n        createdAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query user($userId: String!) {\n    getUser(userId: $userId) {\n      id\n      username\n      fullName\n      bio\n      followerCount\n      followingCount\n      iFollow\n      profilePhoto {\n        smallUrl: url(width: 120, height: 120)\n        largeUrl: url(width: 1200, height: 1200)\n      }\n      recommendations {\n        id\n        description\n        createdByUser {\n          fullName\n          username\n        }\n        location {\n          name\n          geoLocation {\n            latitude\n            longitude\n          }\n          locality\n          googleMapsPlaceId\n          googleMapsUrl\n        }\n        createdAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteRecommendation($recommendationId: String!) {\n    deleteRecommendation(recommendationId: $recommendationId)\n  }\n"): (typeof documents)["\n  mutation deleteRecommendation($recommendationId: String!) {\n    deleteRecommendation(recommendationId: $recommendationId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation recordRecommendationViewed($recommendationId: String!) {\n    recordRecommendationViewed(recommendationId: $recommendationId)\n  }\n"): (typeof documents)["\n  mutation recordRecommendationViewed($recommendationId: String!) {\n    recordRecommendationViewed(recommendationId: $recommendationId)\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;