import { StatusBar } from "expo-status-bar";
import { ProvideAuth } from "./src/hooks/auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ApiProvider, ApiClient } from "./src/hooks/api";

import { NavigationContainer } from "@react-navigation/native";
import BaseNavigator from "./src/screens/Navigator";
import Constants from "expo-constants";
import colors from "tailwindcss/colors";
import { SafeAreaProvider } from "react-native-safe-area-context";
import "react-native-gesture-handler";
import { RootSiblingParent } from "react-native-root-siblings";
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken =
  "pk.eyJ1IjoiZGVhbmVyeSIsImEiOiJjbHI4dTQ4dW0ydzhoMmluZWJwbzhqZXo4In0.cmePcsXWpwCZxV89Xw5cjw";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * 60 * 1000,
    },
  },
});
const FRY_API_BASE_URL = Constants.expoConfig?.extra?.fryApiBaseUrl;

export default function App() {
  return (
    <RootSiblingParent>
      <ApiProvider value={new ApiClient(FRY_API_BASE_URL)}>
        <ProvideAuth>
          <QueryClientProvider client={queryClient}>
            <SafeAreaProvider>
              <NavigationContainer
                theme={{
                  dark: true,
                  colors: {
                    primary: colors.emerald[600],
                    background: colors.zinc[800],
                    border: colors.zinc[600],
                    text: colors.zinc[200],
                    card: colors.zinc[700],
                    notification: colors.red[700],
                  },
                }}
              >
                <StatusBar style="auto"></StatusBar>
                <BaseNavigator />
              </NavigationContainer>
            </SafeAreaProvider>
          </QueryClientProvider>
        </ProvideAuth>
      </ApiProvider>
    </RootSiblingParent>
  );
}
