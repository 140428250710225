import { graphql } from "../gql";

export const nearbyDocument = graphql(`
  query nearby($userLocation: Coordinate!) {
    getNearbyLocations(userLocation: $userLocation, first: 50) {
      edges {
        node {
          id
          name
          geoLocation {
            latitude
            longitude
          }
          recommendationCount
          recommendationsConnection(first: 1) {
            nodes {
              createdByUser {
                username
              }
            }
          }
        }
        distanceMeters
      }
    }
  }
`);

export const locationsInAreaDocument = graphql(`
  query locationsInArea($bounds: GeoAreaBounds!) {
    getLocationsInArea(bounds: $bounds, first: 500) {
      edges {
        node {
          id
          name
          geoLocation {
            latitude
            longitude
          }
          recommendationCount
        }
        distanceMeters
      }
    }
  }
`);
