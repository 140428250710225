import {
  ActivityIndicator,
  Dimensions,
  Image,
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { graphql } from "../gql";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../hooks/auth";
import GoogleMapsLink from "../components/google-maps-link";
import { StackScreenProps } from "@react-navigation/stack";
import { ScrollView } from "react-native";
import RecommendationTile from "../components/recommendation-tile";
import colors from "tailwindcss/colors";

const locationDocument = graphql(`
  query location($locationId: String!, $width: Int!) {
    getLocation(locationId: $locationId) {
      name
      formattedAddress
      googleMapsUrl
      website
      phoneNumber
      staticMapUrl(dimensions: { width: $width, height: 200 })
      recommendations {
        id
        createdByUser {
          fullName
          username
          profilePhoto {
            url(width: 60, height: 60)
          }
        }
        description
        createdAt
      }
    }
  }
`);

const LocationScreen: React.FC<
  StackScreenProps<RootStackParamList, "Location">
> = ({ navigation, route }) => {
  const { graphqlRequest } = useAuth();
  const locationId = route.params.locationId;
  const windowDimensions = Dimensions.get("window");
  const { data } = useQuery({
    queryKey: ["location", locationId],
    queryFn: async () =>
      graphqlRequest(locationDocument, {
        locationId,
        width: Math.min(windowDimensions.width, 1200),
      }),
  });
  if (!data) {
    return <ActivityIndicator />;
  }
  return (
    <ScrollView
      className="flex min-h-0 w-full flex-shrink flex-grow basis-0 items-center justify-start space-y-4"
      contentContainerStyle={{
        // @ts-ignore
        gap: 8,
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <TouchableOpacity
        key="map"
        onPress={() => Linking.openURL(data.getLocation.googleMapsUrl!)}
      >
        <Image
          source={{ uri: data.getLocation.staticMapUrl }}
          style={{
            width: windowDimensions.width,
            maxWidth: windowDimensions.width,
            height: 200,
          }}
        />
      </TouchableOpacity>
      <View key="name" className="p-4">
        <Text className="text-2xl font-bold uppercase text-zinc-200">
          {`${data.getLocation.name}`}
        </Text>
        <Text className="text-sm text-zinc-400">
          {data.getLocation.formattedAddress}
        </Text>
      </View>
      <View key="details" className="w-full p-4">
        <View className="flex w-full flex-col rounded bg-zinc-700">
          {data.getLocation.website != null && (
            <View className="flex-row px-2 py-1">
              <Text className="text-zinc-300" style={{ width: 80 }}>
                Website
              </Text>
              <Text
                className="text-blue-400"
                onPress={() => Linking.openURL(data.getLocation.website!)}
              >
                {data.getLocation.website}
              </Text>
            </View>
          )}
          {data.getLocation.phoneNumber && (
            <View className="flex-row px-2 py-1">
              <Text style={{ width: 80 }} className="text-zinc-300">
                Phone
              </Text>
              <Text className="text-blue-400">
                {data.getLocation.phoneNumber}
              </Text>
            </View>
          )}
          <View className="flex-row px-2 py-1">
            <Text style={{ width: 80 }} className="text-zinc-300">
              Directions
            </Text>
            <GoogleMapsLink googleMapsUrl={data.getLocation.googleMapsUrl!} />
          </View>
        </View>
      </View>

      <Text className="p-4 text-lg font-light text-zinc-200">
        {"Recommended by"}
      </Text>
      {data.getLocation.recommendations.map((rec) => (
        <View className="w-full">
          <View
            style={{
              borderBottomColor: colors.zinc[600],
              borderBottomWidth: StyleSheet.hairlineWidth,
            }}
          />
          <RecommendationTile
            recommendation={{ ...rec, location: data.getLocation }}
            onPress={({ recommendationId }) =>
              navigation.push("ViewRecommendation", {
                recommendationId,
              })
            }
          />
        </View>
      ))}
    </ScrollView>
  );
};

export default LocationScreen;
