import { graphql } from "../gql";

export const followUserMutationDocument = graphql(`
  mutation followUser($userId: String!) {
    followUser(userId: $userId)
  }
`);

export const unfollowUserMutationDocument = graphql(`
  mutation unfollowUser($userId: String!) {
    unfollowUser(userId: $userId)
  }
`);

export const FollowerFollowingUserListFragment = graphql(`
  fragment FollowerFollowingUserListFragment on User {
    id
    username
    fullName
    profilePhoto {
      smallUrl: url(width: 120, height: 120)
    }
    iFollow
    followsMe
  }
`);
