import { View, Platform, Button, Text, ActivityIndicator } from "react-native";
import { graphql } from "../gql";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useAuth } from "../hooks/auth";
import * as ImagePicker from "expo-image-picker";
import ProfilePhoto from "./profile-photo";
import { TouchableOpacity } from "react-native-gesture-handler";
import { showErrorToast } from "./error-toast";

const uploadProfilePhotoDocument = graphql(`
  mutation uploadProfilePhoto($file: Upload!) {
    uploadProfilePhoto(file: $file)
  }
`);

const getProfilePhotoDocument = graphql(`
  query getProfilePhoto {
    getMe {
      profilePhoto {
        largeUrl: url(width: 1200, height: 1200)
      }
    }
  }
`);

type Props = {
  onSuccess?: () => void;
};

const ProfilePictureUpload: React.FC<Props> = ({ onSuccess }) => {
  const { graphqlRequest } = useAuth();
  const profilePhotoQuery = useQuery({
    queryKey: ["profilePhoto"],
    queryFn: async () => graphqlRequest(getProfilePhotoDocument),
  });
  const uploadImageMutation = useMutation(
    async (file: Blob) => graphqlRequest(uploadProfilePhotoDocument, { file }),
    {
      onSuccess: () => {
        profilePhotoQuery.refetch();
        if (onSuccess != null) {
          onSuccess();
        }
      },
      onError: (err) => {
        showErrorToast(err.response.errors[0].message);
      },
    }
  );
  useEffect(() => {
    (async () => {
      if (Platform.OS === "ios") {
        const cameraRollStatus =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        const cameraStatus = await ImagePicker.requestCameraPermissionsAsync();
        if (
          cameraRollStatus.status !== "granted" ||
          cameraStatus.status !== "granted"
        ) {
          alert("Sorry, we need these permissions to make this work!");
        }
      }
    })();
  }, []);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsMultipleSelection: false,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    handleImagePicked(result);
  };

  const handleImagePicked = async (
    pickerResult: ImagePicker.ImagePickerResult
  ) => {
    if (pickerResult.canceled) {
      alert("Upload cancelled");
      return;
    } else {
      const image = await fetchImageFromUri(pickerResult.assets[0].uri);
      uploadImageMutation.mutate(image);
    }
  };

  return (
    <View className="flex w-full items-center justify-center gap-4 p-8">
      {uploadImageMutation.isLoading ? (
        <ActivityIndicator />
      ) : (
        <ProfilePhoto
          size={300}
          imageUri={profilePhotoQuery.data?.getMe.profilePhoto?.largeUrl}
        />
      )}

      <TouchableOpacity
        className="rounded bg-emerald-800 p-4"
        onPress={pickImage}
      >
        <Text className="text-zinc-200">Pick from camera roll</Text>
      </TouchableOpacity>
    </View>
  );
};

export default ProfilePictureUpload;

const fetchImageFromUri = async (uri: string) => {
  const response = await fetch(uri);
  const blob = await response.blob();
  return blob;
};
