import type { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import ProfilePictureUpload from "../components/profile-picture-upload";
import { useQueryClient } from "@tanstack/react-query";

const ChangeProfilePictureScreen: React.FC<
  BottomTabScreenProps<RootStackParamList, "ChangeProfilePicture">
> = ({ navigation, route }) => {
  const queryClient = useQueryClient();
  return (
    <ProfilePictureUpload
      onSuccess={() => {
        queryClient.invalidateQueries();
        navigation.goBack();
      }}
    />
  );
};
export default ChangeProfilePictureScreen;
