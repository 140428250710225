export function isNumeric(value: string): boolean {
  return /^-?\d+$/.test(value);
}

export const timeSince = (date: Date): string => {
  const now = new Date();
  const years_since = now.getUTCFullYear() - date.getUTCFullYear();
  if (years_since > 1) {
    return Math.floor(years_since) + " years";
  }
  const months_since = now.getUTCMonth() - date.getUTCMonth();
  if (months_since > 1) {
    return Math.floor(months_since) + " months";
  }

  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const days_since = seconds / (60 * 60 * 24);
  if (days_since > 1) {
    return Math.floor(days_since) + " days";
  }
  const hours_since = seconds / (60 * 60);
  if (hours_since > 1) {
    return Math.floor(hours_since) + " hours";
  }
  const minutes_since = seconds / 60;
  if (minutes_since > 1) {
    return Math.floor(minutes_since) + " minutes";
  }
  return "seconds";
};
