import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";

export const getItem = (key: string): Promise<string | null> => {
  if (Platform.OS === "web") {
    return Promise.resolve(localStorage.getItem(key));
  }
  if (Platform.OS === "ios" || Platform.OS === "android") {
    return SecureStore.getItemAsync(key);
  }
  throw Error(`unsupported platform ${Platform.OS}`);
};

export const setItem = (key: string, value: string): Promise<void> => {
  if (Platform.OS === "web") {
    return Promise.resolve(localStorage.setItem(key, value));
  }
  if (Platform.OS === "ios" || Platform.OS === "android") {
    return SecureStore.setItemAsync(key, value);
  }
  throw Error(`unsupported platform ${Platform.OS}`);
};

export const deleteItem = (key: string): Promise<void> => {
  if (Platform.OS === "web") {
    return Promise.resolve(localStorage.removeItem(key));
  }
  if (Platform.OS === "ios" || Platform.OS === "android") {
    return SecureStore.deleteItemAsync(key);
  }
  throw Error(`unsupported platform ${Platform.OS}`);
};
