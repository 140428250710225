import React, { useState } from "react";
import { Text, TouchableOpacity, View, TextInput } from "react-native";
import { graphql } from "../gql";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import LocationAutocomplete from "../components/location-autocomplete";
import { Location } from "../types";
import Ionicons from "@expo/vector-icons/Ionicons";
import colors from "tailwindcss/colors";
import { useAuth } from "../hooks/auth";
import { showErrorToast } from "../components/error-toast";

const createRecommendationDocument = graphql(`
  mutation createRecommendation($description: String!, $locationId: String!) {
    createRecommendation(description: $description, locationId: $locationId) {
      id
    }
  }
`);

const NewRecommendationScreen: React.FC<
  NativeStackScreenProps<RootStackParamList, "NewRecommendation">
> = ({ navigation }) => {
  const queryClient = useQueryClient();
  const { graphqlRequest } = useAuth();
  const [location, setLocation] = useState<Location | null>(null);
  const [description, setDescription] = useState("");
  const createRecommendation = useMutation(
    async (variables: { description: string; locationId: string }) =>
      graphqlRequest(createRecommendationDocument, variables),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries();
        navigation.navigate("ProfileDrawer");
      },
      onError: (err) => {
        showErrorToast(err.response.errors[0].message);
      },
    }
  );

  return (
    <View className="flex-1 items-center justify-start space-y-2 p-4">
      <View className="relative z-10 w-full flex-initial">
        <Text className="text-zinc-200">Place</Text>
        {location == null ? (
          <LocationAutocomplete onComplete={setLocation} />
        ) : (
          <View>
            <Text className="text-zinc-200">{location.name}</Text>
            <Text className="text-zinc-200">{location.formattedAddress}</Text>
            <TouchableOpacity onPress={() => setLocation(null)}>
              <Ionicons
                color={colors.zinc[200]}
                name="close-circle"
                size={30}
              />
            </TouchableOpacity>
          </View>
        )}
      </View>

      <View className="relative w-full flex-initial">
        <Text className="text-zinc-200">Description</Text>
        <TextInput
          className="w-full rounded bg-zinc-700 p-2 text-zinc-200"
          multiline
          numberOfLines={10}
          onChangeText={setDescription}
        />
      </View>
      <TouchableOpacity
        className="w-full flex-initial items-center rounded bg-zinc-200 p-4"
        disabled={location == null}
        onPress={() =>
          createRecommendation.mutate({ locationId: location!.id, description })
        }
      >
        <Text className=" text-zinc-800">Submit</Text>
      </TouchableOpacity>
    </View>
  );
};

export default NewRecommendationScreen;
