import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  TextInput,
  StyleSheet,
} from "react-native";
import { graphql } from "../gql";
import { Location } from "../types";
import * as ExpoLocation from "expo-location";
import { useAuth } from "../hooks/auth";
import { useUserLocation } from "../hooks/user-location";

const getLocationAutocompleteSuggestionsDocument = graphql(`
  query getLocationAutocompleteSuggestions(
    $query: String!
    $sessionToken: String
    $userLocation: Coordinate
  ) {
    getLocationAutocompleteSuggestions(
      query: $query
      sessionToken: $sessionToken
      userLocation: $userLocation
    ) {
      predictions {
        placeId
        description
      }
      sessionToken
    }
  }
`);

const selectFromAutocompleteDocument = graphql(`
  query selectFromAutocomplete($placeId: String!, $sessionToken: String!) {
    selectLocationFromAutocomplete(
      placeId: $placeId
      sessionToken: $sessionToken
    ) {
      id
      name
      formattedAddress
      geoLocation {
        latitude
        longitude
      }
      googleMapsPlaceId
      googleMapsUrl
    }
  }
`);

type Props = {
  onComplete: (location: Location) => void;
};

const LocationAutocomplete: React.FC<Props> = ({ onComplete }) => {
  const { graphqlRequest } = useAuth();
  const [query, setQuery] = useState("");
  const [sessionToken, setSessionToken] = useState<string | undefined>(
    undefined
  );
  const [suggestions, setSuggestions] = useState<
    { placeId: string; description: string }[] | null
  >(null);
  const { location } = useUserLocation(true);

  const { mutate: sendAutoCompleteRequest, isLoading } = useMutation(
    async (variables: {
      query: string;
      sessionToken?: string;
      userLocation?: { latitude: number; longitude: number };
    }) => graphqlRequest(getLocationAutocompleteSuggestionsDocument, variables),
    {
      onSuccess: (data) => {
        if (sessionToken == null) {
          setSessionToken(data.getLocationAutocompleteSuggestions.sessionToken);
        }
        setSuggestions(data.getLocationAutocompleteSuggestions.predictions);
      },
    }
  );
  const selectFromAutocomplete = useMutation(
    async (variables: { placeId: string; sessionToken: string }) =>
      graphqlRequest(selectFromAutocompleteDocument, variables),
    {
      onSuccess: (data) => {
        onComplete(data.selectLocationFromAutocomplete);
      },
    }
  );
  const handleQueryChange = (query: string) => {
    setQuery(query);
    if (query === "") {
      setSuggestions(null);
      return;
    }
    if (isLoading) return;
    let userLocation = undefined;
    if (!(location == "loading" || location == "rejected")) {
      userLocation = {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
      };
    }
    sendAutoCompleteRequest({
      query,
      sessionToken,
      userLocation,
    });
  };

  return (
    <View className="w-full">
      <TextInput
        className="w-full rounded bg-zinc-700 p-2 text-zinc-200"
        onChangeText={handleQueryChange}
      ></TextInput>
      {suggestions && sessionToken && (
        <View className="border-1 absolute top-10 w-full rounded bg-zinc-600">
          {intersperse(
            suggestions.map((suggestion) => (
              <TouchableOpacity
                className="min-h-10 w-full p-2"
                onPress={() =>
                  selectFromAutocomplete.mutate({
                    placeId: suggestion.placeId,
                    sessionToken: sessionToken,
                  })
                }
              >
                <Text className="text-left text-zinc-200">
                  {suggestion.description}
                </Text>
              </TouchableOpacity>
            )),
            <View
              className="border-zinc-300"
              style={{
                borderBottomWidth: StyleSheet.hairlineWidth,
              }}
            />
          )}
        </View>
      )}
    </View>
  );
};
export default LocationAutocomplete;

const intersperse = <T, U>(array: Array<T>, item: U): Array<T | U> =>
  array.flatMap((x, i) => (i == array.length - 1 ? [x] : [x, item]));
