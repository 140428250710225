import Toast from "react-native-root-toast";
import colors from "tailwindcss/colors";

export const showErrorToast = (errorMessage: string) => {
  Toast.show(errorMessage, {
    duration: Toast.durations.SHORT,
    position: Toast.positions.TOP,
    containerStyle: {
      width: "90%",
      backgroundColor: colors.red[800],
    },
    shadow: true,
    animation: true,
    delay: 0,
  });
};
