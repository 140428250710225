import React, { useState } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { useApi } from "../../hooks/api";
import PhoneInput from "../phone-input";
import { NavigationProp } from "@react-navigation/native";

type Props = {
  onContinue: (phoneNumber: string) => void;
  navigation: NavigationProp<RootStackParamList>;
};
const PhoneStep: React.FC<Props> = ({ onContinue, navigation }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [valid, setValid] = useState(false);
  const [error, setError] = useState("");
  const handleChange = (phoneNumber: string, isValid: boolean) => {
    setPhoneNumber(phoneNumber);
    setValid(isValid);
  };
  const handleContinue = () => {
    if (!valid) {
      setError("phone number is not valid");
      return;
    }
    onContinue(phoneNumber);
  };
  return (
    <View className="flex-1 items-center justify-between py-20">
      <Text className="text-md text-center text-zinc-200">
        Tastebook is currently invite only
      </Text>
      <Text className="text-md text-zinc-200"></Text>
      <View className="flex-1 items-center justify-start space-y-5">
        <Text className="text-3xl text-zinc-200">
          {"Enter your phone number"}
        </Text>
        {error != null && <Text className="text-zinc-200">{error}</Text>}
        <PhoneInput onPhoneNumberChange={handleChange} />
      </View>
      <TouchableOpacity
        className={
          "w-80 rounded p-4 px-24 " + (valid ? "bg-zinc-500" : "bg-zinc-700")
        }
        onPress={handleContinue}
      >
        <Text className={"text-md text-center text-zinc-200"}>
          {"Continue"}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        className={"my-2 w-full rounded p-2 px-24"}
        onPress={() => navigation.navigate("Login")}
      >
        <Text className={"text-md w-full text-center text-zinc-200"}>
          {"already have an account"}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
export default PhoneStep;
