import React from "react";
import { graphql } from "../gql";
import { ActivityIndicator, Alert, Platform, View } from "react-native";
import { useAuth } from "../hooks/auth";
import { useMutation, useQuery } from "@tanstack/react-query";
import RecommendationTile from "../components/recommendation-tile";
import { DrawerScreenProps } from "@react-navigation/drawer";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import Ionicons from "@expo/vector-icons/Ionicons";
import colors from "tailwindcss/colors";
import { removeRecommendationFromSavedDocument } from "../queries/recommendation";

const getSavedRecommendations = graphql(`
  query getSavedRecommendations {
    getMe {
      savedRecommendations {
        id
        description
        createdAt
        createdByUser {
          fullName
          username
        }
        location {
          name
          geoLocation {
            latitude
            longitude
          }
          googleMapsPlaceId
          googleMapsUrl
        }
      }
    }
  }
`);

const SavedRecommendationsScreen: React.FC<
  DrawerScreenProps<RootStackParamList, "SavedRecommendations">
> = ({ navigation }) => {
  const { graphqlRequest } = useAuth();
  const { data, refetch } = useQuery({
    queryKey: ["savedRecommendations"],
    queryFn: async () => graphqlRequest(getSavedRecommendations),
  });
  const removeRecommendationFromSavedMutation = useMutation(
    async (variables: { recommendationId: string }) =>
      graphqlRequest(removeRecommendationFromSavedDocument, variables),
    {
      onSuccess: () => refetch(),
    }
  );
  if (!data) {
    return <ActivityIndicator />;
  }
  return (
    <ScrollView>
      {data.getMe.savedRecommendations.map((recommendation) => (
        <View
          className="grid w-full grid-cols-5 px-4 py-1"
          key={recommendation.id}
        >
          <View className="col-span-4">
            <RecommendationTile
              recommendation={recommendation}
              onPress={({ recommendationId }) =>
                navigation.navigate("ViewRecommendation", {
                  recommendationId,
                })
              }
            />
          </View>
          <TouchableOpacity
            className="flex grow items-center justify-center rounded bg-zinc-700"
            onPress={() => {
              if (Platform.OS == "web") {
                if (
                  window.confirm(
                    "Are you sure?\nthis recommendation will be removed from your saved list"
                  )
                ) {
                  removeRecommendationFromSavedMutation.mutate({
                    recommendationId: recommendation.id,
                  });
                }
              } else {
                Alert.alert(
                  "Are you sure?",
                  "this recommendation will be removed from your saved list",
                  [
                    { text: "Cancel", style: "cancel" },
                    {
                      text: "DELETE",
                      style: "destructive",
                      onPress: () =>
                        removeRecommendationFromSavedMutation.mutate({
                          recommendationId: recommendation.id,
                        }),
                    },
                  ]
                );
              }
            }}
          >
            <Ionicons name="trash-bin" size={30} color={colors.zinc[200]} />
          </TouchableOpacity>
        </View>
      ))}
    </ScrollView>
  );
};
export default SavedRecommendationsScreen;
