import React from "react";
import { Dimensions, Image } from "react-native";
import colors from "tailwindcss/colors";
import Ionicons from "@expo/vector-icons/Ionicons";

const ProfilePhoto: React.FC<{
  size?: number;
  imageUri: string | null | undefined;
}> = ({ imageUri, size }) => {
  size = size ?? 60;
  const windowWidth = Dimensions.get("window").width;
  return imageUri ? (
    <Image
      source={{ uri: imageUri }}
      style={{
        width: size,
        height: size,
        maxWidth: windowWidth,
        maxHeight: windowWidth,
        borderRadius: size,
      }}
    />
  ) : (
    <Ionicons
      name="person-circle-outline"
      size={size}
      color={colors.zinc[200]}
    />
  );
};
export default ProfilePhoto;
