import { Image, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useAssets } from "expo-asset";
import { TouchableOpacity } from "react-native-gesture-handler";
import { StackScreenProps } from "@react-navigation/stack";

const LandingScreen: React.FC<
  StackScreenProps<RootStackParamList, "Landing">
> = ({ navigation }) => {
  const [assets, error] = useAssets([require("../../assets/icon.png")]);
  return (
    <SafeAreaView className="h-full">
      <View className="flex h-full w-full items-center justify-center">
        <View className="w-56">
          {assets && (
            // @ts-ignore
            <Image
              source={assets[0]}
              style={{
                width: null,
                resizeMode: "contain",
              }}
            ></Image>
          )}
        </View>
        <TouchableOpacity
          className="rounded bg-zinc-200 p-4 px-8"
          onPress={() => navigation.navigate("Signup")}
        >
          <Text className="font-bold text-zinc-900">
            Open those sweet sweet pages
          </Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};
export default LandingScreen;
