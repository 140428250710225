import React, { useState } from "react";
import {
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
} from "react-native";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import PhoneStep from "../components/login/phone-step";
import { useApi } from "../hooks/api";
import PasswordResetStep from "../components/password-reset-step";

const PasswordResetScreen: React.FC<
  NativeStackScreenProps<RootStackParamList, "PasswordReset">
> = ({ navigation }) => {
  let api = useApi();
  const [phoneNumber, setPhoneNumber] = useState("");
  const onPhoneNumberSubmit = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber);
    api.verifyPhoneNumber(phoneNumber);
  };
  const handleSubmit = ({
    code,
    password,
  }: {
    code: string;
    password: string;
  }) => {
    api
      .resetPassword({ phoneNumber, phoneVerificationCode: code, password })
      .then(() => {
        navigation.navigate("Login");
      })
      .catch(() => {
        setPhoneNumber("");
      });
  };
  return (
    <TouchableWithoutFeedback
      onPress={Platform.OS == "web" ? () => {} : Keyboard.dismiss}
    >
      <KeyboardAvoidingView
        behavior="padding"
        className="flex-1 items-center justify-center bg-zinc-900"
      >
        {phoneNumber == "" ? (
          <PhoneStep navigation={navigation} onContinue={onPhoneNumberSubmit} />
        ) : (
          <PasswordResetStep onContinue={handleSubmit} />
        )}
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
};
export default PasswordResetScreen;
