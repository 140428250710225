import React, { useEffect, useState } from "react";
import {
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
  Text,
} from "react-native";
import { useAuth } from "../hooks/auth";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import PhoneStep from "../components/login/phone-step";
import UsernameStep from "../components/login/username-step";
import VerificationCodeStep from "../components/login/verification-code-step";
import { useApi } from "../hooks/api";
import { showErrorToast } from "../components/error-toast";

const LoginSignupScreen: React.FC<
  NativeStackScreenProps<RootStackParamList, "Signup">
> = ({ navigation }) => {
  const auth = useAuth();
  const api = useApi();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  let signup = auth.useSignup({
    onSuccess: () => {
      navigation.navigate("Onboarding");
    },
  });
  const handleSubmit = (phoneVerificationCode: string) => {
    signup.mutate({ username, password, phoneNumber, phoneVerificationCode });
  };
  const handlePhoneStepComplete = (phoneNumber: string) => {
    api
      .checkPhoneNumber(phoneNumber)
      .then((isAvailable) =>
        isAvailable ? setPhoneNumber(phoneNumber) : navigation.navigate("Login")
      )
      .catch((err) => showErrorToast(err.description));
  };
  return (
    <TouchableWithoutFeedback
      onPress={Platform.OS == "web" ? () => {} : Keyboard.dismiss}
    >
      <KeyboardAvoidingView
        behavior="padding"
        className="flex-1 items-center justify-center bg-zinc-900"
      >
        {phoneNumber == "" ? (
          <PhoneStep
            onContinue={handlePhoneStepComplete}
            navigation={navigation}
          />
        ) : username === "" ? (
          <UsernameStep
            onContinue={({ username, password }) => {
              setUsername(username);
              setPassword(password);
            }}
          />
        ) : (
          <VerificationCodeStep
            phoneNumber={phoneNumber}
            onContinue={(code) => {
              handleSubmit(code);
            }}
          />
        )}
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
};
export default LoginSignupScreen;
