import { useCallback, useEffect, useState } from "react";
import * as ExpoLocation from "expo-location";

export const useUserLocation = (isFocused: boolean) => {
  const [location, setLocation] = useState<
    ExpoLocation.LocationObject | "rejected" | "loading"
  >("loading");
  const promptForLocation = useCallback(async () => {
    let resp = await ExpoLocation.getForegroundPermissionsAsync();
    if (resp.status !== "granted") {
      let resp = await ExpoLocation.requestForegroundPermissionsAsync();
      if (resp.status !== "granted") {
        return setLocation("rejected");
      }
    }

    let location = await ExpoLocation.getLastKnownPositionAsync();
    if (location == null) {
      location = await ExpoLocation.getCurrentPositionAsync();
      ExpoLocation.watchPositionAsync({}, (location) => setLocation(location));
    }
    setLocation(location);
  }, [location, setLocation]);
  useEffect(() => {
    if (location != "loading") return;
    if (!isFocused) return;
    promptForLocation();
  }, [isFocused, location, promptForLocation]);
  return {
    location,
    promptForLocation,
  };
};
