import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { graphql } from "../gql";
import type { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import RecommendationTile from "../components/recommendation-tile";
import { useAuth } from "../hooks/auth";
import { FlashList } from "@shopify/flash-list";
import { useIsFocused } from "@react-navigation/native";
import colors from "tailwindcss/colors";
import ProfilePhoto from "../components/profile-photo";
import FollowUnfollowButton from "../components/follow-unfollow-button";
import { useRef, useState } from "react";

const homeDocument = graphql(`
  query homeFeed($after: String) {
    homeFeed(first: 20, after: $after) {
      nodes {
        __typename
        ... on UsersToFollow {
          users {
            id
            username
            fullName
            profilePhoto {
              smallUrl: url(width: 120, height: 120)
            }
            followsMe
            iFollow
          }
        }
        ... on Recommendation {
          id
          location {
            name
            locality
          }
          createdByUser {
            fullName
            username
            profilePhoto {
              url(width: 60, height: 60)
            }
          }
          description
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

const HomeScreen: React.FC<
  BottomTabScreenProps<RootStackParamList, "Home">
> = ({ navigation }) => {
  const { graphqlRequest } = useAuth();
  const isFocused = useIsFocused();
  const listRef = useRef<FlashList<any>>(null);
  const [queryEnabled, setQueryEnabled] = useState(true);
  const queryClient = useQueryClient();
  const { data, fetchNextPage, refetch, isRefetching, isStale } =
    useInfiniteQuery({
      queryKey: ["home"],
      queryFn: async ({ pageParam }) =>
        graphqlRequest(homeDocument, { after: pageParam }),
      getNextPageParam: (lastPage) =>
        lastPage.homeFeed.pageInfo.hasNextPage
          ? lastPage.homeFeed.pageInfo.endCursor
          : undefined,
      enabled: queryEnabled,
      staleTime: 60 * 1000,
      onSuccess: () => setQueryEnabled(false),
    });

  if (!data) {
    return <ActivityIndicator />;
  }
  const allData = data.pages
    .flat()
    .map((data) => data.homeFeed.nodes)
    .flat();

  return (
    <>
      {isStale && (
        <View className="z-50 flex items-center">
          <TouchableOpacity
            className="absolute top-2 z-50 w-24 flex-shrink rounded bg-sky-900 p-2"
            onPress={() => {
              queryClient.resetQueries({ queryKey: ["home"] });
              refetch({ refetchPage: (page, index) => index === 0 });
              listRef.current?.scrollToOffset({ animated: true, offset: 0 });
            }}
          >
            <Text className="flex-shrink text-center text-zinc-200">
              Refresh
            </Text>
          </TouchableOpacity>
        </View>
      )}
      <FlashList<(typeof allData)[number]>
        data={allData}
        ref={listRef}
        refreshing={isRefetching}
        keyExtractor={(item) => {
          switch (item.__typename) {
            case "Recommendation": {
              return item.id;
            }
            case "UsersToFollow": {
              return "users";
            }
          }
          return "";
        }}
        ItemSeparatorComponent={() => (
          <View
            style={{
              borderBottomColor: colors.zinc[600],
              borderBottomWidth: StyleSheet.hairlineWidth,
            }}
          />
        )}
        renderItem={({ item }) => {
          switch (item.__typename) {
            case "Recommendation": {
              return (
                <View className="px-4 py-1">
                  <RecommendationTile
                    recommendation={item}
                    onPress={({ recommendationId }) =>
                      navigation.navigate("ViewRecommendation", {
                        recommendationId,
                      })
                    }
                  />
                </View>
              );
            }
            case "UsersToFollow": {
              if (item.users.length == 0) {
                return <></>;
              }
              let users = item.users.slice(0, 3);
              return (
                <View className="px-4 py-2">
                  <Text className="m-2 text-zinc-200">
                    People you might know
                  </Text>
                  <View className="flex flex-row items-center justify-between">
                    {users.map((user) => (
                      <TouchableOpacity
                        key={user.id}
                        className="m-2 flex-grow items-center justify-center space-y-2 rounded bg-sky-800 p-2"
                        onPress={() =>
                          navigation.navigate("UserProfile", {
                            userId: user.id,
                          })
                        }
                      >
                        <ProfilePhoto
                          size={50}
                          imageUri={user.profilePhoto?.smallUrl}
                        />
                        <Text className="text-zinc-200">{user.username}</Text>
                        <FollowUnfollowButton
                          userId={user.id}
                          iFollow={user.iFollow}
                          buttonClassName="rounded bg-zinc-800 p-2"
                          textClassName="text-zinc-200"
                        />
                      </TouchableOpacity>
                    ))}
                  </View>
                </View>
              );
            }
          }
        }}
        onEndReachedThreshold={0.2}
        onEndReached={() => {
          if (isFocused) {
            fetchNextPage();
          }
        }}
      />
    </>
  );
};

export default HomeScreen;
