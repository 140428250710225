import React, { useEffect, useState } from "react";
import {
  View,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
  Text,
  TouchableOpacity,
} from "react-native";
import { useAuth } from "../hooks/auth";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import TextInput from "../components/text-input";

const LoginScreen: React.FC<
  NativeStackScreenProps<RootStackParamList, "Login">
> = ({ navigation }) => {
  let auth = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);

  let login = auth.useLogin();

  const handleSubmit = () => {
    if (username === "") {
      setError("username cannot be empty");
      return;
    }
    if (password === "") {
      setError("password cannot be empty");
      return;
    }
    setError(null);
    login.mutate({ username, password });
  };
  return (
    <TouchableWithoutFeedback
      onPress={Platform.OS == "web" ? () => {} : Keyboard.dismiss}
    >
      <KeyboardAvoidingView
        behavior="padding"
        className="flex-1 items-center justify-center bg-zinc-900 py-20"
      >
        <View className="flex-1 items-center justify-start space-y-5">
          {error != null && <Text className="text-zinc-200">{error}</Text>}
          <TextInput
            className="
            w-96 min-w-min max-w-full rounded 
            bg-zinc-700 p-4 text-center text-zinc-200 placeholder:text-zinc-200
          "
            placeholder="username"
            placeholderTextColor={"white"}
            onChangeText={setUsername}
          />
          <TextInput
            className="
            w-96 min-w-min max-w-full rounded 
            bg-zinc-700 p-4 text-center text-zinc-200 placeholder:text-zinc-200
          "
            placeholder="password"
            placeholderTextColor={"white"}
            secureTextEntry={true}
            onChangeText={setPassword}
          />
        </View>
        <View className="flex-none items-center space-y-4">
          <TouchableOpacity
            className="w-80 rounded bg-zinc-700 p-4 px-24"
            onPress={handleSubmit}
          >
            <Text className="text-md text-center text-zinc-200">
              {"Log in"}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            className="relative w-80 rounded bg-zinc-800 py-4"
            onPress={() => navigation.navigate("PasswordReset")}
          >
            <Text className="text-md w-full text-center text-zinc-200">
              {"Forgot my password"}
            </Text>
          </TouchableOpacity>
        </View>
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
};
export default LoginScreen;
