import { FlashList } from "@shopify/flash-list";
import React from "react";
import { Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import ProfilePhoto from "./profile-photo";
import { FragmentType, useFragment } from "../gql";
import { FollowerFollowingUserListFragment } from "../queries/follow";
import { NavigationProp } from "@react-navigation/native";
import FollowUnfollowButton from "./follow-unfollow-button";

export type Props = {
  users: Array<FragmentType<typeof FollowerFollowingUserListFragment>>;
  navigation: NavigationProp<RootStackParamList>;
};

const FollowerFollowingList: React.FC<Props> = ({ users, navigation }) => {
  const data = useFragment(FollowerFollowingUserListFragment, users);
  return (
    <View className="min-h-0 w-full flex-1 basis-0">
      <FlashList
        data={data}
        contentContainerStyle={{ padding: 20 }}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => (
          <View className="my-2 flex-row items-center justify-between space-x-1 rounded bg-sky-800 p-4">
            <TouchableOpacity
              className="flex-row items-center space-x-5 px-4"
              onPress={() =>
                navigation.navigate("UserProfile", {
                  userId: item.id,
                })
              }
            >
              <ProfilePhoto size={50} imageUri={item.profilePhoto?.smallUrl} />
              <View className="flex-col">
                <Text className="text-zinc-200">{item.fullName}</Text>
                <Text className="text-zinc-200">{`@${item.username}`}</Text>
              </View>
            </TouchableOpacity>
            <FollowUnfollowButton
              userId={item.id}
              iFollow={item.iFollow}
              buttonClassName="rounded bg-zinc-800 p-4"
              textClassName="text-zinc-200"
            />
          </View>
        )}
      />
    </View>
  );
};
export default FollowerFollowingList;
