import React, { useEffect, useState } from "react";
import { Text, View, TextInput, TouchableOpacity } from "react-native";
import { useApi } from "../../hooks/api";
import { showErrorToast } from "../error-toast";
import { isNumeric } from "../../util";

type Props = {
  phoneNumber: string;
  onContinue: (code: string) => void;
};
const VerificationCodeStep: React.FC<Props> = ({ phoneNumber, onContinue }) => {
  const [code, setCode] = useState("");
  const sendCode = () => {
    api
      .verifyPhoneNumber(phoneNumber)
      .catch((err) => showErrorToast(err.description));
  };
  let api = useApi();
  useEffect(sendCode, []);
  return (
    <View className="flex-1 items-center justify-between py-20">
      <View className="flex-1 items-center justify-start space-y-5">
        <Text className="font-sans text-5xl text-zinc-200">
          {"We sent you a text"}
        </Text>
        <TextInput
          className="
            w-96 min-w-min max-w-full rounded 
            bg-zinc-700 p-4 text-center text-zinc-200 placeholder:text-zinc-200
          "
          placeholder="enter the code"
          placeholderTextColor={"white"}
          onChangeText={setCode}
          keyboardType="number-pad"
          onKeyPress={(e) => {
            if (
              !(isNumeric(e.nativeEvent.key) || e.nativeEvent.key == "Backspace")
            ) {
              e.preventDefault();
            }
          }}
        />
      </View>
      <View className="gap-2">
        <TouchableOpacity
          className="w-80 rounded bg-zinc-800 p-4 px-24"
          onPress={sendCode}
        >
          <Text className="text-md text-center text-zinc-200">
            {"resend code"}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          className="w-80 rounded bg-zinc-700 p-4 px-24"
          onPress={() => onContinue(code)}
        >
          <Text className="text-md text-center text-zinc-200">
            {"Continue"}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
export default VerificationCodeStep;
