import { ActivityIndicator } from "react-native";
import { graphql } from "../gql";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../hooks/auth";
import { StackScreenProps } from "@react-navigation/stack";
import FollowerFollowingList from "../components/follower-following-list";

const getFollowingQuery = graphql(`
  query getFollowing($userId: String!) {
    getUser(userId: $userId) {
      following {
        ...FollowerFollowingUserListFragment
      }
    }
  }
`);

const FollowingScreen: React.FC<
  StackScreenProps<RootStackParamList, "Following">
> = ({ navigation, route }) => {
  const { graphqlRequest } = useAuth();
  const userId = route.params.userId;
  const { data } = useQuery({
    queryKey: ["userFollowing", userId],
    queryFn: async () => graphqlRequest(getFollowingQuery, { userId }),
  });
  if (!data) {
    return <ActivityIndicator />;
  }
  return (
    <FollowerFollowingList
      users={data.getUser.following}
      navigation={navigation}
    />
  );
};

export default FollowingScreen;
