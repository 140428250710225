import request from "graphql-request";
import { type TypedDocumentNode } from "@graphql-typed-document-node/core";
import { createContext, useContext } from "react";

export type Visitor = {
  type: "visitor";
  visitorId: string;
  accessToken: string;
};
export type AuthenticatedUser = {
  type: "authenticated";
  userId: string;
  accessToken: string;
};
export type User = Visitor | AuthenticatedUser;

export type LoginResponse = {
  userId: string;
  accessToken: string;
  refreshToken: string;
};

export type VisitorAccessTokenResponse = {
  visitorToken: string;
};

export class ApiClient {
  constructor(private baseUrl: string) {}
  public createVisitorAccessToken(): Promise<VisitorAccessTokenResponse> {
    return fetch(`${this.baseUrl}/auth/visitor-token`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (resp) => {
      let json = await resp.json();
      if (!resp.ok) {
        throw json;
      }
      return {
        visitorToken: json.visitor_token,
      };
    });
  }
  public login(params: {
    username: string;
    password: string;
  }): Promise<LoginResponse> {
    return fetch(`${this.baseUrl}/auth/login`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then(async (resp) => {
        let json = await resp.json();
        if (!resp.ok) {
          throw json;
        }
        return json;
      })
      .then((data) => ({
        accessToken: data.access_token,
        userId: data.user_id,
        refreshToken: data.refresh_token,
      }));
  }
  signup(params: {
    username: string;
    password: string;
    phoneNumber: string;
    phoneVerificationCode: string;
  }): Promise<LoginResponse> {
    return fetch(`${this.baseUrl}/auth/sign-up`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: params.username,
        password: params.password,
        phone_number: params.phoneNumber,
        phone_verification_code: params.phoneVerificationCode,
      }),
    })
      .then(async (resp) => {
        let json = await resp.json();
        if (!resp.ok) {
          throw json;
        }
        return json;
      })
      .then((data) => ({
        accessToken: data.access_token,
        userId: data.user_id,
        refreshToken: data.refresh_token,
      }));
  }
  loginWithRefreshToken(params: {
    refreshToken: string;
  }): Promise<LoginResponse> {
    return fetch(`${this.baseUrl}/auth/refresh-token`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh_token: params.refreshToken,
      }),
    })
      .then(async (resp) => {
        let json = await resp.json();
        if (!resp.ok) {
          throw json;
        }
        return json;
      })
      .then((data) => ({
        accessToken: data.access_token,
        userId: data.user_id,
        refreshToken: data.refresh_token,
      }));
  }
  revokeRefreshToken(params: { refreshToken: string }): Promise<void> {
    return fetch(`${this.baseUrl}/auth/refresh-token/revoke`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh_token: params.refreshToken,
      }),
    }).then(async (resp) => {
      if (!resp.ok) {
        throw "failed to reset password";
      }
    });
  }
  resetPassword(params: {
    phoneNumber: string;
    phoneVerificationCode: string;
    password: string;
  }): Promise<void> {
    return fetch(`${this.baseUrl}/auth/password-reset`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone_number: params.phoneNumber,
        phone_verification_code: params.phoneVerificationCode,
        password: params.password,
      }),
    }).then(async (resp) => {
      if (!resp.ok) {
        let json = await resp.json();
        throw json;
      }
    });
  }
  getUserByUsername(username: string): Promise<{
    userId: string;
    username: string;
  } | null> {
    return fetch(`${this.baseUrl}/user/with_username/${username}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (resp) => {
      let json = await resp.json();
      if (resp.status == 404) {
        return null;
      } else if (!resp.ok) {
        throw json;
      } else {
        return {
          userId: json.userId,
          username: json.username,
        };
      }
    });
  }
  getMeAuth(accessToken: string): Promise<{
    userId: string;
    isAdmin: boolean;
  }> {
    return fetch(`${this.baseUrl}/auth/me`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async (resp) => {
        let json = await resp.json();
        if (!resp.ok) {
          throw json;
        }
        return json;
      })
      .then((data) => ({
        userId: data.user_id,
        isAdmin: data.is_admin,
      }));
  }
  sendUserInvite(params: {
    accessToken: string;
    phoneNumber: string;
    shouldResend: boolean;
  }): Promise<void> {
    return fetch(`${this.baseUrl}/auth/send-invite`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${params.accessToken}`,
      },
      body: JSON.stringify({
        phone_number: params.phoneNumber,
        should_resend: params.shouldResend,
      }),
    }).then(async (resp) => {
      let json = await resp.json();
      if (!resp.ok) {
        throw json;
      }
    });
  }
  checkPhoneNumber(phoneNumber: string): Promise<boolean> {
    return fetch(`${this.baseUrl}/auth/phone/check`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone_number: phoneNumber,
      }),
    }).then(async (resp) => {
      let json = await resp.json();
      if (!resp.ok) {
        throw json;
      }
      return json;
    });
  }
  verifyPhoneNumber(phoneNumber: string): Promise<void> {
    return fetch(`${this.baseUrl}/auth/phone/verify`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone_number: phoneNumber,
      }),
    }).then(async (resp) => {
      if (!resp.ok) {
        let json = await resp.json();
        throw json;
      }
      return;
    });
  }
  async graphqlRequest<TResult, TVariables>(
    accessToken: string,
    graphqlDocument: TypedDocumentNode<TResult, TVariables>,
    ...variables: TVariables extends Record<string, never> ? [] : [TVariables]
  ): Promise<TResult> {
    return request(
      `${this.baseUrl}/graphql`,
      graphqlDocument,
      variables[0] ? variables[0] : undefined,
      { authorization: `Bearer ${accessToken}` }
    );
  }
}

const apiContext = createContext<ApiClient | null>(null);

export const useApi = () => {
  return useContext(apiContext)!;
};

export const ApiProvider = apiContext.Provider;
