import { graphql } from "../gql";

export const setFullNameDocument = graphql(`
  mutation setFullName($fullName: String) {
    setFullName(fullName: $fullName)
  }
`);

export const setBioDocument = graphql(`
  mutation setBio($bio: String) {
    setBio(bio: $bio)
  }
`);
