import React, { useState } from "react";
import { Text, View, TextInput, TouchableOpacity } from "react-native";
import { useApi } from "../../hooks/api";

type Props = {
  onContinue: (values: { username: string; password: string }) => void;
};
const UsernameStep: React.FC<Props> = ({ onContinue }) => {
  const api = useApi()!;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const handleContinue = () => {
    if (username === "") {
      setError("username cannot be empty");
      return;
    }
    if (password.length < 8) {
      setError("password must be at least 8 characters long");
      return;
    }
    setError(null);
    api.getUserByUsername(username).then((user) => {
      if (user === null) {
        onContinue({ username, password });
      } else {
        setError("username already taken");
      }
    });
  };
  return (
    <View className="flex-1 items-center justify-between py-20">
      <View className="flex-1 items-center justify-start space-y-5">
        <Text className="font-sans text-2xl text-zinc-200">
          {"Pick a username"}
        </Text>
        {error != null && <Text className="text-zinc-200">{error}</Text>}
        <TextInput
          className="
            w-96 min-w-min max-w-full rounded 
            bg-zinc-700 p-4 text-center text-zinc-200 placeholder:text-zinc-200
          "
          placeholder="username"
          placeholderTextColor={"white"}
          onChangeText={setUsername}
        />
        <Text className="font-sans text-2xl text-zinc-200">
          {"and password"}
        </Text>
        <TextInput
          className="
            w-96 min-w-min max-w-full rounded 
            bg-zinc-700 p-4 text-center text-zinc-200 placeholder:text-zinc-200
          "
          placeholder="password"
          placeholderTextColor={"white"}
          secureTextEntry={true}
          onChangeText={setPassword}
        />
      </View>
      <TouchableOpacity
        className="w-80 rounded bg-zinc-700 p-4 px-24"
        onPress={handleContinue}
      >
        <Text className="text-md text-center text-zinc-200">{"Continue"}</Text>
      </TouchableOpacity>
    </View>
  );
};
export default UsernameStep;
