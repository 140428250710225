import { Platform } from "react-native";
import mapboxgl from "mapbox-gl";
import { useDeferredValue, useEffect, useRef, useState } from "react";
import { locationsInAreaDocument } from "../queries/location";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../hooks/auth";
import { NavigationProp, useNavigation } from "@react-navigation/native";

type GeoLocation = { latitude: number; longitude: number };
type Bounds = { sw: GeoLocation; ne: GeoLocation };
const Map: React.FC<{}> = () => {
  if (Platform.OS !== "web") {
    throw new Error("only web is supported");
  }
  const navigator = useNavigation<NavigationProp<RootStackParamList>>();
  const { graphqlRequest } = useAuth();
  const mapObjectRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const markers = useRef<Array<mapboxgl.Marker>>([]);
  const [currentBounds, setCurrentBounds] = useState<Bounds | null>(null);
  const debouncedBounds = useDeferredValue(currentBounds);
  const { refetch } = useQuery({
    queryKey: ["locations-in-area", debouncedBounds],
    queryFn: async () => {
      return graphqlRequest(locationsInAreaDocument, {
        bounds: debouncedBounds!,
      });
    },
    enabled: debouncedBounds !== null,
    onSuccess: (data) => {
      markers.current.forEach((marker) => marker.remove());
      data.getLocationsInArea.edges.forEach(({ node }) => {
        if (node.geoLocation == null) {
          return;
        }
        const marker = new mapboxgl.Marker()
          .setLngLat([node.geoLocation.longitude, node.geoLocation.latitude])
          .addTo(mapObjectRef.current!);
        markers.current.push(marker);
        marker.getElement().addEventListener("click", () => {
          navigator.navigate("Location", {
            locationId: node.id,
          });
        });
      });
    },
  });
  useEffect(() => {
    if (mapObjectRef.current) return;
    if (mapContainerRef.current == null) return;
    mapObjectRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/deanery/cls6ioo3t02ek01nl4v1scxlq", // style URL
      center: [-79.38, 43.65], // starting position [lng, lat]
      zoom: 12, // starting zoom
    });
    mapObjectRef.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      }),
      "bottom-right"
    );
    mapObjectRef.current.addControl(
      new mapboxgl.NavigationControl(),
      "bottom-right"
    );
    let updateOnBoundsChange = () => {
      let bounds = mapObjectRef.current!.getBounds();
      setCurrentBounds({
        sw: { longitude: bounds._sw.lng, latitude: bounds._sw.lat },
        ne: { longitude: bounds._ne.lng, latitude: bounds._ne.lat },
      });
    };
    mapObjectRef.current.on("zoomend", updateOnBoundsChange);
    mapObjectRef.current.on("dragend", updateOnBoundsChange);
    updateOnBoundsChange();
    refetch();
  }, [mapContainerRef.current, mapObjectRef.current]);
  return <div ref={mapContainerRef} className="z-50 h-full"></div>;
};
export default Map;
