import { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import React from "react";
import Map from "../components/map";

const MapScreen: React.FC<
  BottomTabScreenProps<RootStackParamList, "Map">
> = () => {
  return <Map />;
};

export default MapScreen;
