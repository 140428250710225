import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { Text, TouchableOpacity } from "react-native";
import {
  followUserMutationDocument,
  unfollowUserMutationDocument,
} from "../queries/follow";
import { useAuth } from "../hooks/auth";
import { AuthenticatedUser } from "../hooks/api";

export type Props = {
  userId: string;
  iFollow: boolean;
  buttonClassName: string;
  textClassName: string;
};

const FollowUnfollowButton: React.FC<Props> = ({
  userId,
  iFollow,
  buttonClassName,
  textClassName,
}) => {
  const [iFollowOpt, setIFollowOpt] = useState(iFollow);
  const queryClient = useQueryClient();
  const { graphqlRequest, user } = useAuth();
  const invalidate = () => {
    queryClient.invalidateQueries({
      queryKey: ["userFollowing", (user as AuthenticatedUser).userId],
    });
    queryClient.invalidateQueries({
      queryKey: ["me"],
    });
    queryClient.invalidateQueries({
      queryKey: ["user", userId],
    });
    queryClient.invalidateQueries({
      queryKey: ["userFollowers", userId],
    });
    queryClient.invalidateQueries({
      queryKey: ["home"],
    });
  };
  const followMutation = useMutation(
    async () => {
      return graphqlRequest(followUserMutationDocument, { userId });
    },
    {
      onSuccess: () => {
        setIFollowOpt(true);
        invalidate();
      },
    }
  );
  const unfollowMutation = useMutation(
    async () => {
      return graphqlRequest(unfollowUserMutationDocument, { userId });
    },
    {
      onSuccess: () => {
        setIFollowOpt(false);
        invalidate();
      },
    }
  );
  return (
    <>
      {iFollowOpt ? (
        <TouchableOpacity
          className={buttonClassName}
          disabled={unfollowMutation.isLoading}
          onPress={() => unfollowMutation.mutate()}
        >
          <Text className={textClassName}>unfollow</Text>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          className={buttonClassName}
          disabled={followMutation.isLoading}
          onPress={() => followMutation.mutate()}
        >
          <Text className={textClassName}>follow</Text>
        </TouchableOpacity>
      )}
    </>
  );
};
export default FollowUnfollowButton;
